<template>
    <span
        :class="disabled ? 'module-disabled-struct' : ''"
        :uk-tooltip="
            disabled
                ? 'title:' + $t('x.module.disabled.hint', {x: $t('gallery')})
                : ''
        ">
        <span
            :class="
                disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'
            ">
            <LoadingStruct :is-loading="loading" is-animation-disabled>
                <LabelWithError :label="$t('used.storage.size')" is-bold />
                <div class="uk-margin-small-bottom" />

                <Translate
                    :parameter="{
                        used: getParsedBytes(stats.sizeUsageInBytes),
                        total: getParsedBytes(stats.sizeLimitInBytes),
                    }"
                    translation-key="x.of.total.x.used" />
                <progress
                    :value="stats.sizeUsedInPercentage"
                    class="uk-progress"
                    max="100" />

                <InfoBox v-if="isSizeLimitReached" icon="warning" mode="danger">
                    <Translate translation-key="storage.size.limit.reached" />
                </InfoBox>

                <div class="uk-margin-medium-top" />

                <span
                    :uk-tooltip="
                        'title:' +
                        (isSizeLimitReached
                            ? $t('storage.size.limit.reached')
                            : '')
                    ">
                    <Button
                        :action="actionNew"
                        :is-disabled="isSizeLimitReached"
                        has-action
                        mode="primary">
                        {{ $t('x.add', {x: $t('gallery')}) }}
                    </Button>
                </span>

                <div class="uk-margin-top" />
                <div v-if="!computedList || computedList.length === 0">
                    {{ $t('none.x', {x: $t('galleries')}) }}
                </div>
                <div v-else class="uk-overflow-auto">
                    <table
                        class="uk-table uk-table-middle uk-table-divider uk-table-hover">
                        <thead>
                            <tr>
                                <th class="uk-table-shrink">
                                    {{ $t('thumbnail') }}
                                </th>
                                <th class="uk-table-shrink">
                                    {{ $t('name') }}
                                </th>

                                <th class="uk-table-shrink">
                                    {{ $t('reachability') }}
                                </th>

                                <th class="uk-table-shrink">
                                    {{ $t('article.list') }}
                                </th>

                                <th class="uk-table-shrink">
                                    {{ $t('active') }}
                                </th>

                                <th class="uk-table-shrink">
                                    {{ $t('public') }}
                                </th>

                                <th class="uk-table-shrink">
                                    {{ $t('password') }}
                                </th>

                                <th class="uk-table-shrink">
                                    {{ $t('share.link') }}
                                </th>

                                <th class="uk-table-shrink">
                                    {{ $t('cropping') }}
                                </th>

                                <th class="uk-table-shrink">
                                    {{ $t('folders') }}
                                </th>
                                <th class="uk-table-shrink">
                                    {{ $t('pictures') }}
                                </th>
                                <th class="uk-table-shrink">
                                    {{ $t('size') }}
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(gallery, key) in computedList"
                                :key="'article-' + key"
                                :class="
                                    'cursor-pointer uk-text-nowrap' +
                                    (gallery.id === hoveredId
                                        ? ' uk-table-hovered'
                                        : '')
                                ">
                                <td v-on:click="actionSelect(gallery)">
                                    <div
                                        class="uk-card uk-card-default uk-card-hover cursor-pointer">
                                        <div
                                            class="uk-inline uk-transition-toggle uk-width-1-1">
                                            <div v-if="gallery.thumbnail">
                                                <div
                                                    :data-src="
                                                        apiUrl +
                                                        '/manage-galleries/' +
                                                        gallery.id +
                                                        '/thumbnail/' +
                                                        gallery.thumbnail +
                                                        '?token=' +
                                                        getToken
                                                    "
                                                    class="thumbnail-height uk-background-cover"
                                                    uk-img />
                                            </div>
                                            <div v-else>
                                                <div
                                                    :data-src="
                                                        require('../../assets/default_thumbnail.jpg')
                                                    "
                                                    class="thumbnail-height uk-background-cover"
                                                    uk-img />
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td v-on:click="actionSelect(gallery)">
                                    <Icon
                                        class="uk-margin-small-right"
                                        icon="tag" />
                                    {{ gallery.name }}
                                </td>

                                <td v-on:click="actionSelect(gallery)">
                                    <Icon
                                        v-if="gallery.disabled"
                                        :uk-tooltip="
                                            'title:' + $t('gallery.disabled')
                                        "
                                        class="uk-form-danger"
                                        icon="ban" />

                                    <Icon
                                        v-else-if="!gallery.articleListID"
                                        :uk-tooltip="
                                            'title:' +
                                            $t('not.available.no.articlelist')
                                        "
                                        class="uk-form-danger"
                                        icon="close" />

                                    <Icon
                                        v-else-if="
                                            !isArticleListReachable(
                                                gallery.articleListID
                                            )
                                        "
                                        :uk-tooltip="
                                            'title:' +
                                            $t(
                                                'not.available.articlelist.not.reachable'
                                            )
                                        "
                                        class="uk-form-danger"
                                        icon="close" />

                                    <div
                                        class="uk-grid-small uk-child-width-auto"
                                        v-else-if="
                                            gallery.hasPassword ||
                                            gallery.shareToken ||
                                            gallery.isPublic
                                        "
                                        uk-grid>
                                        <div v-if="gallery.isPublic">
                                            <Icon
                                                :uk-tooltip="
                                                    'title:' +
                                                    $t('available.public')
                                                "
                                                class="uk-form-success"
                                                icon="eye" />
                                        </div>
                                        <div v-if="gallery.hasPassword">
                                            <Icon
                                                :uk-tooltip="
                                                    'title:' +
                                                    $t('available.password')
                                                "
                                                class="uk-form-success"
                                                icon="lock" />
                                        </div>
                                        <div v-if="gallery.shareToken">
                                            <Icon
                                                :uk-tooltip="
                                                    'title:' +
                                                    $t('available.sharelink')
                                                "
                                                class="uk-form-success"
                                                icon="social" />
                                        </div>
                                    </div>

                                    <Icon
                                        v-else
                                        :uk-tooltip="
                                            'title:' + $t('not.available')
                                        "
                                        icon="close" />
                                </td>

                                <td v-on:click="actionSelect(gallery)">
                                    <Icon
                                        v-if="gallery.articleListID"
                                        :uk-tooltip="
                                            'title:' +
                                            $t('with.x', {
                                                x: $t('article.list'),
                                            })
                                        "
                                        icon="list" />
                                    <Icon
                                        v-else
                                        :uk-tooltip="
                                            'title:' +
                                            $t('without.x', {
                                                x: $t('article.list'),
                                            })
                                        "
                                        icon="file" />
                                </td>

                                <td v-on:click="actionSelect(gallery)">
                                    <Icon
                                        v-if="gallery.disabled"
                                        :uk-tooltip="
                                            'title:' + $t('gallery.disabled')
                                        "
                                        icon="ban" />
                                    <Icon
                                        v-else-if="gallery.disableAt"
                                        :uk-tooltip="
                                            'title:' +
                                            $t('gallery.will.be.disabled', {
                                                x: getDaysLeftByDateString(
                                                    gallery.disableAt,
                                                    true
                                                ),
                                                y: getFormattedDateTimeByDateString(
                                                    gallery.disableAt
                                                ),
                                            })
                                        "
                                        icon="future" />

                                    <Icon
                                        v-else
                                        :uk-tooltip="
                                            'title:' +
                                            $t('gallery.not.disabled')
                                        "
                                        icon="bolt" />
                                </td>

                                <td v-on:click="actionSelect(gallery)">
                                    <Icon
                                        v-if="gallery.isPublic"
                                        :uk-tooltip="'title:' + $t('public')"
                                        icon="eye" />
                                    <Icon
                                        v-else
                                        :uk-tooltip="
                                            'title:' + $t('not.public')
                                        "
                                        icon="eye-closed" />
                                </td>

                                <td v-on:click="actionSelect(gallery)">
                                    <Icon
                                        v-if="gallery.hasPassword"
                                        :uk-tooltip="
                                            'title:' + $t('with.password')
                                        "
                                        icon="lock" />
                                    <Icon
                                        v-else
                                        :uk-tooltip="
                                            'title:' +
                                            $t('without.x', {
                                                x: $t('password'),
                                            })
                                        "
                                        icon="close" />
                                </td>

                                <td v-on:click="actionSelect(gallery)">
                                    <Icon
                                        v-if="gallery.shareToken"
                                        :uk-tooltip="
                                            'title:' + $t('with.sharelink')
                                        "
                                        icon="social" />
                                    <Icon
                                        v-else
                                        :uk-tooltip="
                                            'title:' +
                                            $t('without.x', {
                                                x: $t('share.link'),
                                            })
                                        "
                                        icon="close" />
                                </td>

                                <td v-on:click="actionSelect(gallery)">
                                    <Icon
                                        v-if="!gallery.isCroppingDisabled"
                                        :uk-tooltip="
                                            'title:' +
                                            $t('with.x', {
                                                x: $t('cropping'),
                                            })
                                        "
                                        icon="move" />
                                    <Icon
                                        v-else
                                        :uk-tooltip="
                                            'title:' +
                                            $t('without.x', {
                                                x: $t('cropping'),
                                            })
                                        "
                                        icon="close" />
                                </td>

                                <td v-on:click="actionSelect(gallery)">
                                    <Icon
                                        class="uk-margin-small-right"
                                        icon="folder" />
                                    {{ gallery.stats.folders }}
                                </td>
                                <td v-on:click="actionSelect(gallery)">
                                    <Icon
                                        class="uk-margin-small-right"
                                        icon="image" />
                                    {{ gallery.stats.picturesTotal }}
                                </td>
                                <td v-on:click="actionSelect(gallery)">
                                    <Icon
                                        class="uk-margin-small-right"
                                        icon="database" />
                                    {{
                                        getParsedBytes(
                                            gallery.stats.sizeUsageInBytes
                                        )
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="uk-margin-medium-top" />
                <span
                    :uk-tooltip="
                        'title:' +
                        (isSizeLimitReached
                            ? $t('storage.size.limit.reached')
                            : '')
                    ">
                    <Button
                        v-if="computedList && computedList.length > 0"
                        :action="actionNew"
                        :is-disabled="isSizeLimitReached"
                        has-action
                        mode="primary">
                        {{ $t('x.add', {x: $t('gallery')}) }}
                    </Button>
                </span>
            </LoadingStruct>

            <Modal
                :id="modalIdDetails"
                :title="
                    (selected ? selected.name + ': ' : '') +
                    $t(selected ? 'x.change' : 'x.add', {x: $t('gallery')})
                "
                id-title="modal-title"
                is-extended>
                <InfoBox v-if="selected" mode="theme">
                    <Translate
                        v-if="selected.disabled"
                        translation-key="gallery.disabled" />
                    <Translate
                        v-else-if="!selected.articleListID"
                        translation-key="gallery.article.list.info" />
                    <Translate
                        v-else-if="
                            !isArticleListReachable(selected.articleListID)
                        "
                        translation-key="gallery.article.list.no.active" />

                    <div
                        v-else-if="
                            selected.isPublic ||
                            selected.shareToken ||
                            selected.hasPassword
                        ">
                        <div v-if="selected.isPublic">
                            <Translate translation-key="gallery.public.info" />
                        </div>

                        <div v-if="selected.hasPassword">
                            <Translate
                                translation-key="gallery.password.info" />
                        </div>

                        <div v-if="selected.shareToken">
                            <Translate
                                translation-key="gallery.share.link.info" />
                        </div>
                    </div>

                    <Translate v-else translation-key="gallery.not.reachable" />
                </InfoBox>

                <div class="uk-margin-medium-top" />

                <ul uk-tab="animation: uk-animation-fade">
                    <li
                        v-for="tab in ['pictures', 'folders', 'settings']"
                        :key="tab">
                        <a href="#">{{ $t(tab) }}</a>
                    </li>
                </ul>
                <ul v-if="selected" class="uk-switcher uk-margin">
                    <li>
                        <Translate
                            translation-key="gallery.files.description" />

                        <div class="uk-margin-top" />
                        <div
                            class="uk-grid uk-grid-small uk-child-width-1-6@l uk-child-width-1-5@m uk-child-width-1-4@s uk-child-width-1-3 uk-light"
                            uk-grid>
                            <div>
                                <span v-on:click="actionSelectFolder(null)">
                                    <LabelSelect
                                        :is-selected="selectedFolder === null"
                                        class="uk-text-center uk-padding-small">
                                        <img
                                            v-if="selected.thumbnail"
                                            :src="
                                                apiUrl +
                                                '/manage-galleries/' +
                                                selected.id +
                                                '/thumbnail/' +
                                                selected.thumbnail +
                                                '?token=' +
                                                getToken
                                            "
                                            class="favicon-folder"
                                            uk-img />
                                        <img
                                            v-else
                                            :src="
                                                require('../../assets/default_thumbnail.jpg')
                                            "
                                            class="favicon-folder"
                                            uk-img />

                                        <div
                                            :uk-tooltip="
                                                'title:' + selected.name
                                            "
                                            class="folder-name">
                                            {{ selected.name }}

                                            <div class="folder-sub">
                                                ({{ $t('folder.root') }})
                                            </div>
                                        </div>
                                    </LabelSelect>
                                </span>
                            </div>

                            <div
                                v-for="folder in selected.folders"
                                :key="folder.name">
                                <span v-on:click="actionSelectFolder(folder)">
                                    <LabelSelect
                                        :is-selected="
                                            selectedFolder &&
                                            selectedFolder.name === folder.name
                                        "
                                        class="uk-text-center uk-padding-small">
                                        <Icon
                                            v-if="false"
                                            :ratio="1.5"
                                            icon="folder" />

                                        <img
                                            v-if="folder.thumbnail"
                                            :src="
                                                apiUrl +
                                                '/manage-galleries/' +
                                                selected.id +
                                                '/folders/' +
                                                folder.name +
                                                '/thumbnail/' +
                                                folder.thumbnail +
                                                '?token=' +
                                                getToken
                                            "
                                            class="favicon-folder"
                                            uk-img />
                                        <img
                                            v-else
                                            :src="
                                                require('../../assets/default_thumbnail.jpg')
                                            "
                                            class="favicon-folder"
                                            uk-img />

                                        <div
                                            :uk-tooltip="'title:' + folder.name"
                                            class="folder-name">
                                            {{ folder.name }}
                                        </div>
                                    </LabelSelect>
                                </span>
                            </div>
                        </div>

                        <InfoBox
                            v-if="isSizeLimitReached"
                            class="uk-margin-medium-bottom"
                            icon="warning"
                            mode="danger">
                            <Translate
                                translation-key="storage.size.limit.reached" />
                        </InfoBox>
                        <div
                            v-else
                            id="pictures-upload"
                            class="js-upload uk-placeholder uk-placeholder-hover uk-text-center cursor-pointer"
                            v-on:click="triggerInputDialog()">
                            <div>
                                <Icon
                                    :ratio="2.2"
                                    class="upload-icon"
                                    icon="cloud-upload" />
                            </div>

                            <div v-if="isDeviceMobile && isUploadInProgress">
                                <span class="uk-text-middle">{{
                                    $t('upload.in.progress.hint')
                                }}</span>
                            </div>
                            <div v-else>
                                <span class="uk-text-middle">{{
                                    $t('x.drop.or.', {x: $t('pictures')})
                                }}</span>
                                <span
                                    class="uk-link uk-text-middle custom-link"
                                    >{{ $t('select') }}</span
                                >
                            </div>

                            <div class="uk-hidden" uk-form-custom>
                                <input
                                    id="pictures"
                                    ref="pictures"
                                    :accept="'.' + fileExtensions.join(', .')"
                                    multiple
                                    type="file"
                                    v-on:change="handleFilesUpload()"
                                    v-on:click="$refs.pictures.value = null" />
                            </div>

                            <div v-if="picturesError" class="uk-margin-top" />
                            <ErrorMessageString
                                :custom-errors="[
                                    {
                                        code: 507,
                                        message: $t(
                                            'storage.size.limit.reached'
                                        ),
                                    },
                                    {
                                        code: 422,
                                        message:
                                            $t('files.type.error', {
                                                name: $t('pictures'),
                                                types: this.getFormattedTypesString(
                                                    this.fileExtensions
                                                ),
                                            }) + '.',
                                    },
                                ]"
                                :error="picturesError"
                                class="uk-form-danger" />
                        </div>

                        <progress
                            v-if="
                                getUploadPercentage > 0 &&
                                getUploadPercentage < 100
                            "
                            :value="getUploadPercentage"
                            class="uk-progress uk-animation-fade"
                            max="100" />

                        <ErrorMessage v-if="filesError" :error="filesError" />
                        <div v-else-if="filesLoading" class="uk-flex-center">
                            <div uk-spinner />
                        </div>
                        <div
                            v-else-if="!files || files.length === 0"
                            class="uk-text-center">
                            <Translate
                                :parameter="{x: $t('pictures')}"
                                translation-key="none.x" />
                        </div>
                        <div
                            v-else
                            class="uk-grid-small uk-child-width-1-6@l uk-child-width-1-5@m uk-child-width-1-4@s uk-child-width-1-2 terminal-content"
                            uk-grid>
                            <div v-for="(file, key) in files" :key="'gf' + key">
                                <GalleryFile
                                    :folder="selectedFolder"
                                    :gallery-id="selected.id"
                                    :item="file"
                                    :item-key="key"
                                    :is-thumbnail="
                                        selectedFolder
                                            ? selectedFolder.thumbnailSet ===
                                              file.name
                                            : selected.thumbnailSet ===
                                              file.name
                                    "
                                    @updated="actionUpdated"
                                    @deleted="actionSoftDeleteFile" />
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="uk-width-medium">
                            <span
                                v-for="(inputField, key) in folderFields"
                                :key="inputField.name">
                                <TextField
                                    v-model="inputField.value"
                                    :autocomplete="inputField.autocomplete"
                                    :autofocus="inputField.autofocus"
                                    :field-key="key"
                                    :hasPasswordMeter="
                                        inputField.hasPasswordMeter
                                    "
                                    :icon="inputField.icon"
                                    :is-optional="inputField.optional"
                                    :label="inputField.label"
                                    :name="inputField.name"
                                    :on-enter="actionCreateFolder"
                                    :options="inputField.options"
                                    :rules="inputField.rules"
                                    :steps="inputField.steps"
                                    :type="inputField.type"
                                    :validate="triggerFolder"
                                    @setValid="folderSetValid" />
                                <div
                                    v-if="key < folderFields.length - 1"
                                    class="uk-margin-bottom" />
                            </span>

                            <div class="uk-margin-top" />
                            <Button
                                :action="actionCreateFolder"
                                :is-loading="folderLoading"
                                has-action
                                is-large
                                is-max
                                mode="theme">
                                <Translate translation-key="folder.add" />
                            </Button>

                            <div class="uk-margin-medium-top" />
                        </div>
                        <div
                            class="uk-grid uk-grid-small uk-child-width-1-6@l uk-child-width-1-5@m uk-child-width-1-4@s uk-child-width-1-3 uk-light"
                            uk-grid>
                            <div>
                                <span v-on:click="actionSelectFolder(null)">
                                    <LabelSelect
                                        :is-selected="selectedFolder === null"
                                        class="uk-text-center uk-padding-small">
                                        <img
                                            v-if="selected.thumbnail"
                                            :src="
                                                apiUrl +
                                                '/manage-galleries/' +
                                                selected.id +
                                                '/thumbnail/' +
                                                selected.thumbnail +
                                                '?token=' +
                                                getToken
                                            "
                                            class="favicon-folder"
                                            uk-img />
                                        <img
                                            v-else
                                            :src="
                                                require('../../assets/default_thumbnail.jpg')
                                            "
                                            class="favicon-folder"
                                            uk-img />

                                        <div
                                            :uk-tooltip="
                                                'title:' + selected.name
                                            "
                                            class="folder-name">
                                            {{ selected.name }}

                                            <div class="folder-sub">
                                                ({{ $t('folder.root') }})
                                            </div>
                                        </div>
                                    </LabelSelect>
                                </span>
                            </div>

                            <div
                                v-for="folder in selected.folders"
                                :key="folder.name">
                                <span v-on:click="actionSelectFolder(folder)">
                                    <LabelSelect
                                        :is-selected="
                                            selectedFolder &&
                                            selectedFolder.name === folder.name
                                        "
                                        class="uk-text-center uk-padding-small">
                                        <Icon
                                            v-if="false"
                                            :ratio="1.5"
                                            icon="folder" />

                                        <img
                                            v-if="folder.thumbnail"
                                            :src="
                                                apiUrl +
                                                '/manage-galleries/' +
                                                selected.id +
                                                '/folders/' +
                                                folder.name +
                                                '/thumbnail/' +
                                                folder.thumbnail +
                                                '?token=' +
                                                getToken
                                            "
                                            class="favicon-folder"
                                            uk-img />
                                        <img
                                            v-else
                                            :src="
                                                require('../../assets/default_thumbnail.jpg')
                                            "
                                            class="favicon-folder"
                                            uk-img />

                                        <div
                                            :uk-tooltip="'title:' + folder.name"
                                            class="folder-name">
                                            {{ folder.name }}
                                        </div>
                                    </LabelSelect>
                                </span>
                            </div>
                        </div>

                        <hr class="uk-hr" />

                        <div
                            class="uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match"
                            uk-grid>
                            <div>
                                <Card is-dark mode="default">
                                    <span
                                        v-for="(inputField, key) in nameFields"
                                        :key="inputField.name">
                                        <TextField
                                            v-model="inputField.value"
                                            :autocomplete="
                                                inputField.autocomplete
                                            "
                                            :autofocus="inputField.autofocus"
                                            :field-key="key"
                                            :hasPasswordMeter="
                                                inputField.hasPasswordMeter
                                            "
                                            :icon="inputField.icon"
                                            :is-optional="inputField.optional"
                                            :label="inputField.label"
                                            :name="inputField.name"
                                            :on-enter="actionUpdateName"
                                            :options="inputField.options"
                                            :rules="inputField.rules"
                                            :steps="inputField.steps"
                                            :type="inputField.type"
                                            :validate="triggerName"
                                            is-label-large
                                            @setValid="nameSetValid" />
                                        <div
                                            v-if="key < nameFields.length - 1"
                                            class="uk-margin-bottom" />
                                    </span>

                                    <div class="uk-margin-medium-bottom" />
                                    <Button
                                        :action="actionUpdateName"
                                        :is-loading="nameLoading"
                                        :text="
                                            $t('x.save', {
                                                x: $t(
                                                    this.selectedFolder
                                                        ? 'folder.name'
                                                        : 'name'
                                                ),
                                            })
                                        "
                                        has-action
                                        is-large
                                        is-max
                                        mode="primary" />

                                    <div
                                        v-if="nameError"
                                        class="uk-margin-medium-bottom" />
                                    <ErrorMessage :error="nameError" />

                                    <div
                                        v-if="nameSuccess"
                                        class="uk-margin-medium-bottom" />
                                    <SuccessMessage
                                        :message="
                                            $t('x.changes.saved', {
                                                x: $t('name'),
                                            })
                                        "
                                        :success="nameSuccess" />
                                </Card>
                            </div>

                            <div>
                                <GalleryThumbnailForm
                                    :id="selected.id"
                                    :allowed-extensions="thumbnailExtensions"
                                    :allowed-size="thumbnailMaxSizeInBytes"
                                    :data="
                                        selectedFolder
                                            ? selectedFolder.thumbnail
                                            : selected.thumbnail
                                    "
                                    :disabled="disabled"
                                    :folder="selectedFolder"
                                    @updated="actionUpdated" />
                            </div>

                            <div v-if="selectedFolder !== null">
                                <Card is-dark mode="default">
                                    <LabelWithError
                                        :label="$t('delete')"
                                        is-label-large />

                                    <Button
                                        :action="actionDelete"
                                        has-action
                                        is-large
                                        is-max
                                        mode="danger">
                                        <Translate
                                            :parameter="{
                                                x: $t(
                                                    this.selectedFolder
                                                        ? 'folder'
                                                        : 'gallery'
                                                ),
                                            }"
                                            translation-key="x.delete" />
                                    </Button>
                                </Card>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div
                            class="uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match"
                            uk-grid>
                            <div>
                                <Card is-dark mode="default">
                                    <LabelWithError
                                        :label="$t('password')"
                                        is-label-large />

                                    <Description>
                                        <Translate
                                            translation-key="gallery.password.description" />
                                    </Description>

                                    <div v-if="selected.hasPassword">
                                        <Translate
                                            v-if="!selected.password"
                                            translation-key="gallery.password.set" />
                                        <div
                                            v-if="!selected.password"
                                            class="uk-margin-top" />

                                        <TextField
                                            v-if="
                                                selected.password &&
                                                selected.password !== '********'
                                            "
                                            :value="selected.password"
                                            type="text"
                                            icon="lock"
                                            disabled
                                            name="gallery-password"
                                            label="gallery.password"
                                            :action-function="codeToClipboard"
                                            action-icon="copy-clipboard"
                                            :action-tooltip="
                                                $t('copy.to.clipboard')
                                            " />
                                        <TextField
                                            v-if="
                                                selected.password &&
                                                selected.password === '********'
                                            "
                                            :value="selected.password"
                                            type="text"
                                            icon="lock"
                                            disabled
                                            name="gallery-password"
                                            label="gallery.password" />

                                        <div
                                            v-if="selected.password"
                                            class="uk-margin-medium-bottom" />

                                        <Button
                                            :action="actionDeletePassword"
                                            :is-loading="passwordDeleteLoading"
                                            has-action
                                            is-large
                                            is-max
                                            mode="danger">
                                            <Translate
                                                translation-key="x.delete"
                                                :parameter="{
                                                    x: $t('password'),
                                                }" />
                                        </Button>

                                        <div
                                            v-if="passwordDeleteError"
                                            class="uk-margin-medium-bottom" />
                                        <ErrorMessage
                                            :error="passwordDeleteError" />
                                    </div>

                                    <div v-if="!selected.hasPassword">
                                        <TextField
                                            :validate="passwordTrigger"
                                            @setValid="passwordSetValid"
                                            is-show-password
                                            v-model="selected.password"
                                            :value="selected.password"
                                            type="password"
                                            :on-enter="actionUpdatePassword"
                                            icon="lock"
                                            name="gallery-password"
                                            label="gallery.password"
                                            rules="required|min:8"
                                            has-password-meter />

                                        <div class="uk-margin-medium-top" />
                                        <Button
                                            :action="actionUpdatePassword"
                                            :is-loading="passwordLoading"
                                            has-action
                                            is-large
                                            is-max
                                            mode="primary">
                                            <Translate
                                                translation-key="set.password" />
                                        </Button>

                                        <div
                                            v-if="passwordError"
                                            class="uk-margin-medium-bottom" />
                                        <ErrorMessage
                                            :custom-errors="[
                                                {
                                                    code: 424,
                                                    message: $t(
                                                        'gallery.password.duplicate.error'
                                                    ),
                                                },
                                            ]"
                                            :error="passwordError" />
                                    </div>

                                    <div
                                        v-if="passwordDeleteSuccess"
                                        class="uk-margin-medium-bottom" />
                                    <SuccessMessage
                                        :message="
                                            $t(
                                                'gallery.password.deleted.successfully'
                                            )
                                        "
                                        :success="passwordDeleteSuccess" />

                                    <div
                                        v-if="passwordSuccess"
                                        class="uk-margin-medium-bottom" />
                                    <SuccessMessage
                                        :message="
                                            $t('x.changes.saved', {
                                                x: $t('password'),
                                            })
                                        "
                                        :success="passwordSuccess" />
                                </Card>
                            </div>

                            <div>
                                <Card is-dark mode="default">
                                    <LabelWithError
                                        :label="$t('share.link')"
                                        is-label-large />
                                    <div
                                        v-if="selected.shareToken"
                                        class="uk-grid-collapse uk-grid-match"
                                        uk-grid>
                                        <div class="uk-width-expand">
                                            <TextField
                                                :action-function="copyShareLink"
                                                :action-tooltip="
                                                    $t('copy.to.clipboard')
                                                "
                                                :value="shareLink()"
                                                action-icon="copy-clipboard"
                                                icon="link" />
                                        </div>
                                        <div class="uk-width-auto">
                                            <span
                                                :uk-tooltip="
                                                    'title:' +
                                                    $t('generate.qrcode')
                                                ">
                                                <Button
                                                    :action="
                                                        generateQRCodeSharelink
                                                    "
                                                    has-action
                                                    is-large
                                                    is-max
                                                    mode="default">
                                                    <Icon icon="grid" />
                                                </Button>
                                            </span>
                                        </div>

                                        <div class="uk-width-1-1">
                                            <div class="uk-margin-medium-top" />

                                            <Button
                                                :action="actionCreateShareLink"
                                                :is-loading="shareLoading"
                                                has-action
                                                is-large
                                                is-max
                                                mode="danger">
                                                <Translate
                                                    translation-key="share.link.regenerate" />
                                            </Button>

                                            <div class="uk-margin-medium-top" />
                                            <Button
                                                :action="actionDeleteShareLink"
                                                :is-loading="shareLoading"
                                                has-action
                                                is-large
                                                is-max
                                                mode="danger">
                                                <Translate
                                                    translation-key="delete.share.link" />
                                            </Button>
                                        </div>
                                    </div>
                                    <Button
                                        v-else
                                        :action="actionCreateShareLink"
                                        :is-loading="shareLoading"
                                        class=""
                                        has-action
                                        is-large
                                        is-max
                                        mode="theme">
                                        <Translate
                                            translation-key="generate.share.link" />
                                    </Button>

                                    <div
                                        v-if="shareError"
                                        class="uk-margin-medium-bottom" />
                                    <ErrorMessage :error="shareError" />

                                    <div
                                        v-if="shareSuccess"
                                        class="uk-margin-medium-bottom" />
                                    <SuccessMessage
                                        :message="
                                            $t('x.changes.saved', {
                                                x: $t('share.link'),
                                            })
                                        "
                                        :success="shareSuccess" />
                                </Card>
                            </div>

                            <div>
                                <Card is-dark mode="default">
                                    <LabelWithError
                                        :label="$t('public')"
                                        is-label-large />
                                    <SwitchButton
                                        v-model="selected.isPublic"
                                        is-label-hidden
                                        label="public"
                                        @input="publicSuccess = false" />

                                    <div class="uk-margin-bottom" />
                                    <LabelWithError
                                        v-if="selected.isPublic"
                                        :label="$t('gallery.public.link')" />

                                    <div
                                        v-if="selected.isPublic"
                                        class="uk-grid-collapse"
                                        uk-grid>
                                        <div class="uk-width-expand">
                                            <TextField
                                                :action-function="copyLink"
                                                :action-tooltip="
                                                    $t('copy.to.clipboard')
                                                "
                                                :value="link()"
                                                action-icon="copy-clipboard"
                                                icon="link" />
                                        </div>
                                        <div class="uk-width-auto">
                                            <span
                                                :uk-tooltip="
                                                    'title:' +
                                                    $t('generate.qrcode')
                                                ">
                                                <Button
                                                    :action="
                                                        generateQRCodePublic
                                                    "
                                                    has-action
                                                    is-large
                                                    is-max
                                                    mode="default">
                                                    <Icon icon="grid" />
                                                </Button>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="uk-margin-medium-bottom" />
                                    <Button
                                        :action="actionUpdatePublic"
                                        :is-loading="publicLoading"
                                        :text="$t('x.save', {x: $t('public')})"
                                        has-action
                                        is-large
                                        is-max
                                        mode="primary" />

                                    <div
                                        v-if="publicError"
                                        class="uk-margin-medium-bottom" />
                                    <ErrorMessage :error="publicError" />

                                    <div
                                        v-if="publicSuccess"
                                        class="uk-margin-medium-bottom" />
                                    <SuccessMessage
                                        :message="
                                            $t('x.changes.saved', {
                                                x: $t('public'),
                                            })
                                        "
                                        :success="publicSuccess" />
                                </Card>
                            </div>

                            <div>
                                <Card is-dark mode="default">
                                    <LabelWithError
                                        :label="
                                            $t('article.list') +
                                            ' (' +
                                            $t('optional') +
                                            ')'
                                        "
                                        is-label-large />

                                    <select
                                        id="gallery-article-selector"
                                        v-model="selected.articleListID"
                                        class="uk-select uk-input-styled"
                                        v-on:change="
                                            articleListSuccess = false
                                        ">
                                        <option value="" />
                                        <option
                                            v-for="articleList in articleLists"
                                            :key="'al' + articleList.id"
                                            :value="articleList.id">
                                            {{ articleList.name }}
                                        </option>
                                    </select>

                                    <div class="uk-margin-medium-bottom" />
                                    <Button
                                        :action="actionUpdateArticleList"
                                        :is-loading="articleListLoading"
                                        :text="
                                            $t('x.save', {
                                                x: $t('article.list'),
                                            })
                                        "
                                        has-action
                                        is-large
                                        is-max
                                        mode="primary" />

                                    <div
                                        v-if="articleListError"
                                        class="uk-margin-medium-bottom" />
                                    <ErrorMessage :error="articleListError" />

                                    <div
                                        v-if="articleListSuccess"
                                        class="uk-margin-medium-bottom" />
                                    <SuccessMessage
                                        :message="
                                            $t('x.changes.saved', {
                                                x: $t('article.list'),
                                            })
                                        "
                                        :success="articleListSuccess" />
                                </Card>
                            </div>

                            <div>
                                <Card is-dark mode="default">
                                    <LabelWithError
                                        :label="$t('disable')"
                                        is-label-large />

                                    <SwitchButton
                                        v-model="selected.disabled"
                                        id="gallery-disable"
                                        @input="disabledHandler" />

                                    <div class="uk-margin-bottom" />
                                    <SwitchButton
                                        :is-disabled="selected.disabled"
                                        v-model="withDisableDate"
                                        :label="
                                            $t('with.x', {
                                                x: $t('disable.date'),
                                            })
                                        "
                                        @input="disableSuccess = false" />

                                    <div
                                        v-if="withDisableDate"
                                        class="uk-animation-fade">
                                        <div class="uk-margin-bottom" />

                                        <v-date-picker
                                            v-model="selected.disableAt"
                                            is-expanded
                                            mode="datetime"
                                            is24hr
                                            :locale="settings.language" />
                                    </div>

                                    <div class="uk-margin-medium-bottom" />
                                    <Button
                                        :action="actionDisable"
                                        :is-loading="disableLoading"
                                        :text="
                                            $t('x.save', {
                                                x: $t('disable'),
                                            })
                                        "
                                        has-action
                                        is-large
                                        is-max
                                        mode="primary" />

                                    <div
                                        v-if="disableError"
                                        class="uk-margin-medium-bottom" />
                                    <ErrorMessage :error="disableError" />

                                    <div
                                        v-if="disableSuccess"
                                        class="uk-margin-medium-bottom" />
                                    <SuccessMessage
                                        :message="
                                            $t('x.changes.saved', {
                                                x: $t('disable'),
                                            })
                                        "
                                        :success="disableSuccess" />
                                </Card>
                            </div>

                            <div>
                                <Card is-dark mode="default">
                                    <LabelWithError
                                        :label="$t('disable.cropping')"
                                        is-label-large />

                                    <SwitchButton
                                        v-model="selected.isCroppingDisabled"
                                        id="gallery-disable-cropping" />

                                    <div class="uk-margin-medium-bottom" />
                                    <Button
                                        :action="actionDisableCropping"
                                        :is-loading="disableCroppingLoading"
                                        :text="
                                            $t('x.save', {
                                                x: $t('disable.cropping'),
                                            })
                                        "
                                        has-action
                                        is-large
                                        is-max
                                        mode="primary" />

                                    <div
                                        v-if="disableCroppingError"
                                        class="uk-margin-medium-bottom" />
                                    <ErrorMessage
                                        :error="disableCroppingError" />

                                    <div
                                        v-if="disableCroppingSuccess"
                                        class="uk-margin-medium-bottom" />
                                    <SuccessMessage
                                        :message="
                                            $t('x.changes.saved', {
                                                x: $t('disable.cropping'),
                                            })
                                        "
                                        :success="disableCroppingSuccess" />
                                </Card>
                            </div>

                            <div>
                                <Card is-dark mode="default">
                                    <LabelWithError
                                        :label="$t('delete')"
                                        is-label-large />

                                    <Button
                                        :action="actionDelete"
                                        has-action
                                        is-large
                                        is-max
                                        mode="danger">
                                        <Translate
                                            :parameter="{
                                                x: $t(
                                                    this.selectedFolder
                                                        ? 'folder'
                                                        : 'gallery'
                                                ),
                                            }"
                                            translation-key="x.delete" />
                                    </Button>
                                </Card>
                            </div>
                        </div>
                    </li>
                </ul>
            </Modal>

            <Modal
                :id="modalIdDelete"
                :title="
                    (selectedFolder
                        ? selectedFolder.name + ': '
                        : selected
                        ? selected.name + ': '
                        : '') +
                    $t('x.delete', {
                        x: $t(this.selectedFolder ? 'folder' : 'gallery'),
                    })
                ">
                <div class="uk-text-center">
                    <Translate
                        v-if="selectedFolder"
                        translation-key="folder.delete.confirm" />
                    <Translate
                        v-else
                        translation-key="gallery.delete.confirm" />
                </div>

                <div
                    class="uk-flex uk-flex-center uk-margin-medium-top"
                    uk-grid>
                    <div>
                        <Button
                            :text="$t('cancel')"
                            class="uk-modal-close"
                            is-large
                            is-max
                            is-min-width
                            mode="default" />
                    </div>
                    <div>
                        <Button
                            :action="actionDeleteConfirmed"
                            :is-loading="deleteLoading"
                            :text="
                                $t('x.delete', {
                                    x: $t(
                                        this.selectedFolder
                                            ? 'folder'
                                            : 'gallery'
                                    ),
                                })
                            "
                            has-action
                            is-large
                            is-max
                            is-min-width
                            mode="danger" />
                    </div>
                </div>

                <div v-if="deleteError" class="uk-margin-medium-bottom" />
                <ErrorMessage :error="deleteError" />

                <div v-if="deleteSuccess" class="uk-margin-medium-bottom" />
                <SuccessMessage
                    :message="$t('x.changes.saved', {x: $t('public')})"
                    :success="deleteSuccess" />
            </Modal>

            <Modal :id="modalIdCreate" :title="$t('x.add', {x: $t('gallery')})">
                <span
                    v-for="(inputField, key) in nameFields"
                    :key="inputField.name">
                    <TextField
                        v-model="inputField.value"
                        :autocomplete="inputField.autocomplete"
                        :autofocus="inputField.autofocus"
                        :field-key="key"
                        :hasPasswordMeter="inputField.hasPasswordMeter"
                        :icon="inputField.icon"
                        :is-optional="inputField.optional"
                        :label="inputField.label"
                        :label-info="inputField.labelInfo"
                        :name="inputField.name"
                        :on-enter="actionCreate"
                        :options="inputField.options"
                        :rules="inputField.rules"
                        :steps="inputField.steps"
                        :type="inputField.type"
                        :validate="triggerName"
                        @setValid="nameSetValid" />

                    <div
                        v-if="key < nameFields.length - 1"
                        class="uk-margin-bottom" />
                </span>

                <div class="uk-margin-medium-top" />
                <Button
                    :action="actionCreate"
                    :is-loading="createLoading"
                    :text="$t('x.add', {x: $t('gallery')})"
                    has-action
                    is-large
                    is-max
                    is-min-width
                    mode="theme" />
            </Modal>
        </span>
    </span>
</template>

<script>
import Button from '@/components/generic/Button.vue';
import axios from 'axios';
import LoadingStruct from '@/views/LoadingStruct.vue';
import Icon from '@/components/generic/Icon.vue';
import LabelWithError from '@/components/generic/LabelWithError.vue';
import Translate from '@/components/Translate.vue';
import InfoBox from '@/components/generic/InfoBox.vue';
import Modal from '@/components/generic/Modal.vue';
import UIkit from 'uikit';
import TextField from '@/components/generic/TextField.vue';
import SwitchButton from '@/components/generic/SwitchButton.vue';
import GalleryThumbnailForm from '@/components/gallery/GalleryThumbnailForm.vue';
import {isMobileOnly} from 'mobile-device-detect';
import ErrorMessage from '@/components/generic/ErrorMessage.vue';
import SuccessMessage from '@/components/generic/SuccessMessage.vue';
import Card from '@/components/generic/Card.vue';
import GalleryFile from '@/components/forms/GalleryFile.vue';
import {uuid} from 'vue-uuid';
import ErrorMessageString from '@/components/generic/ErrorMessageString.vue';
import LabelSelect from '@/components/LabelSelect.vue';
import Description from '@/components/Description.vue';

export default {
    name: 'GalleriesForm',
    components: {
        Description,
        LabelSelect,
        ErrorMessageString,
        GalleryFile,
        Card,
        SuccessMessage,
        ErrorMessage,
        GalleryThumbnailForm,
        SwitchButton,
        TextField,
        Modal,
        InfoBox,
        Translate,
        LabelWithError,
        Icon,
        LoadingStruct,
        Button,
    },
    props: {
        disabled: Boolean,
        articleLists: Array,
        settings: Object,
    },
    data() {
        return {
            // ids
            modalIdCreate: 'modal-gallery-create',
            modalIdDetails: 'modal-gallery-details',
            modalIdDelete: 'modal-gallery-delete',

            listenerInited: false,

            // selected
            hoveredId: null,
            selected: null,
            selectedFolder: null,

            random: null,

            // data
            galleries: [],
            stats: {
                sizeUsageInBytes: 0,
                sizeLimitInBytes: 0,
                sizeUsedInPercentage: 0,
            },
            error: null,
            loading: true,

            // share
            shareLoading: false,
            shareSuccess: false,
            shareError: null,

            // password
            passwordLoading: false,
            passwordSuccess: false,
            passwordError: null,
            passwordTrigger: 0,
            passwordValid: false,

            // password delete
            passwordDeleteLoading: false,
            passwordDeleteSuccess: false,
            passwordDeleteError: null,

            // create
            createLoading: false,
            createSuccess: false,
            createError: null,

            // delete
            deleteLoading: false,
            deleteSuccess: false,
            deleteError: null,

            // name
            triggerName: 0,
            nameFields: [
                {
                    value: '',
                    valid: true,

                    name: 'name',
                    label: 'name',
                    icon: 'tag',
                    rules: 'required|max:23',
                    type: 'text',
                },
            ],
            nameLoading: false,
            nameSuccess: false,
            nameError: null,

            // folder
            triggerFolder: 0,
            folderFields: [
                {
                    value: '',
                    valid: false,

                    name: 'folder-name',
                    label: 'folder.name',
                    icon: 'folder',
                    rules: 'required|max:23|unique:',
                    type: 'text',
                },
            ],
            folderLoading: false,
            folderSuccess: false,
            folderError: null,

            // articlelist
            articleListLoading: false,
            articleListSuccess: false,
            articleListError: null,

            // public
            publicLoading: false,
            publicSuccess: false,
            publicError: null,

            // files
            isDeviceMobile: isMobileOnly,
            isUploadInProgress: false,
            filesLoading: false,
            filesError: null,
            files: [],

            // pictures
            intervalUpload: 0,
            pictures: [],
            picturesError: null,
            pictureTypeErrors: [],
            pictureSizeErrors: [],
            pictureLimitErrors: [],

            // disable
            withDisableDate: false,
            disableLoading: false,
            disableSuccess: false,
            disableError: null,

            // disable cropping
            disableCroppingLoading: false,
            disableCroppingSuccess: false,
            disableCroppingError: null,
        };
    },
    computed: {
        thumbnailExtensions() {
            if (this.settings.gallery.thumbnailExtensions) {
                return this.settings.gallery.thumbnailExtensions;
            }

            return [];
        },
        thumbnailMaxSizeInBytes() {
            if (this.settings.gallery.thumbnailMaxSizeInBytes) {
                return this.settings.gallery.thumbnailMaxSizeInBytes;
            }

            return 0;
        },
        isSizeLimitReached() {
            return this.stats.sizeUsedInPercentage >= 100;
        },
        computedList() {
            let list = this.galleries;

            if (!list) {
                return [];
            }

            return list;
        },
        getUploadPercentage() {
            const pictures = this.pictures;
            let total = 0;
            for (let i = 0, j = pictures.length; i < j; i++) {
                total += pictures[i].uploadPercentage;
            }

            let totalPercentage = total / pictures.length;
            return (Math.round(totalPercentage * 100) / 100).toFixed(0);
        },
    },
    created() {
        this.actionGetStats();

        this.uploadQueue();
    },
    mounted() {
        window.addEventListener('dragover', this.dragoverListener, false);
    },
    methods: {
        disabledHandler(val) {
            if (val) {
                this.withDisableDate = false;
            }
        },
        dragoverListener(e) {
            e.preventDefault();

            let element = document.getElementById('pictures-upload');
            element.classList.add('dragover');
        },
        dropListener(e) {
            e.preventDefault();

            let element = document.getElementById('pictures-upload');
            element.classList.remove('dragover');

            let files = e.dataTransfer.files;

            this.processFilesUpload(files);
        },
        isArticleListReachable(id) {
            for (let i = 0, j = this.articleLists.length; i < j; i++) {
                const articleList = this.articleLists[i];

                if (articleList.id !== id) {
                    continue;
                }

                return articleList.isReachable;
            }

            return false;
        },
        processFileEntries(files) {
            let self = this;

            for (let i = 0, j = files.length; i < j; i++) {
                /*
                if (self.pictures.length >= this.getInfos.maxPicturesPerOrder) {
                    this.pictureLimitErrors.push(files[i].name);
                    continue;
                }
                */

                let newFileStruct = {
                    id: uuid.v4(),

                    file: files[i],
                    thumbnail: null,
                    thumbnailUrl: null,

                    uploadPercentage: 0,
                    error: null,
                    isDone: false,
                    inProgress: false,
                };

                self.pictures.push(newFileStruct);
            }
        },
        processFilesUpload(files) {
            this.picturesError = null;
            let parsedFiles = [];

            for (let i = 0, j = files.length; i < j; i++) {
                let fileExtension = files[i].name.split('.').pop();
                fileExtension = fileExtension.toLowerCase();
                if (!this.fileExtensions.includes(fileExtension)) {
                    this.pictureTypeErrors.push(files[i].name);
                    continue;
                }

                if (files[i].size > this.getMaxFileSizeInMB * 1024 * 1024) {
                    this.pictureSizeErrors.push(files[i].name);
                    continue;
                }

                parsedFiles.push(files[i]);
            }

            if (parsedFiles.length === 0) {
                this.showPictureErrors();
            }

            this.processFileEntries(parsedFiles);
            this.showPictureErrors();
        },
        showPictureErrors() {
            const messageTimeout = 0;

            if (this.pictureTypeErrors.length > 0) {
                UIkit.notification({
                    message:
                        this.$t('files.type.error', {
                            name: this.$t('pictures'),
                            types: this.getFormattedTypesString(
                                this.fileExtensions
                            ),
                        }) + '.',
                    status: 'danger',
                    pos: 'top-center',
                    timeout: messageTimeout,
                });

                this.pictureTypeErrors = [];
            }

            if (this.pictureSizeErrors.length > 0) {
                UIkit.notification({
                    message:
                        this.$t('files.size.error', {
                            name: this.$t('pictures'),
                            mb: this.getMaxFileSizeInMB,
                        }) + '.',
                    status: 'danger',
                    pos: 'top-center',
                    timeout: messageTimeout,
                });

                this.pictureSizeErrors = [];
            }

            if (this.pictureLimitErrors.length > 0) {
                UIkit.notification({
                    message:
                        this.$t('pictures.max.reached.drop', {
                            x: this.getInfos.maxPicturesPerOrder,
                        }) + '.',
                    status: 'danger',
                    pos: 'top-center',
                    timeout: messageTimeout,
                });

                this.pictureLimitErrors = [];
            }
        },
        actionCreateFile(file) {
            let formData = new FormData();
            formData.append('file', file.file, file.file.name);

            const config = {
                onUploadProgress: function (progressEvent) {
                    file.uploadPercentage = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                },
            };

            let folder = '';
            if (this.selectedFolder) {
                folder = '/folders/' + this.selectedFolder.name;
            }

            let http = axios.create();
            http.post(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.selected.id +
                    folder +
                    '/files',
                formData,
                config
            )
                .then((response) => {
                    file.uploadPercentage = 100;
                    file.inProgress = false;
                    file.isDone = true;

                    this.actionGetStats();

                    if (this.files === undefined || this.files === null) {
                        this.files = [];
                    }

                    this.files.push(response.data);
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.picturesError = e;

                    file.uploadPercentage = 100;
                    file.error = e;
                    file.inProgress = false;
                    file.isDone = true;
                });
        },
        handleFilesUpload() {
            let files = this.$refs['pictures'].files;
            this.processFilesUpload(files);
        },
        uploadQueue() {
            let self = this;
            this.intervalUpload = setInterval(() => {
                for (let i = 0, j = self.pictures.length; i < j; i++) {
                    if (self.pictures[i].inProgress) {
                        return;
                    }
                }

                for (let i = 0, j = self.pictures.length; i < j; i++) {
                    if (self.pictures[i].isDone) {
                        continue;
                    }

                    self.pictures[i].inProgress = true;
                    self.actionCreateFile(self.pictures[i]);

                    return;
                }
            }, 100);
        },
        triggerInputDialog() {
            document.getElementById('pictures').click();
        },
        actionSelectFolder(folder) {
            this.nameSuccess = false;

            this.selectedFolder = folder;

            if (folder) {
                let names = [];
                if (this.selected.folders) {
                    for (
                        let i = 0, j = this.selected.folders.length;
                        i < j;
                        i++
                    ) {
                        if (this.selected.folders[i].name === folder.name) {
                            continue;
                        }

                        names.push(this.selected.folders[i].name);
                    }
                }

                this.nameFields[0].value = folder.name;
                this.nameFields[0].valid = true;
                this.nameFields[0].rules =
                    'required|max:23|unique:' + names.join(',');
            } else {
                this.nameFields[0].value = this.selected.name;
                this.nameFields[0].valid = true;
                this.nameFields[0].rules = 'required|max:23';
            }

            this.actionGetFiles();
        },
        actionGetFiles() {
            let http = axios.create();

            let folder = '';
            if (this.selectedFolder) {
                folder = '/folders/' + this.selectedFolder.name;
            }

            this.filesLoading = true;
            http.get(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.selected.id +
                    folder +
                    '/files'
            )
                .then((response) => {
                    this.files = response.data;

                    this.filesError = null;
                    this.filesLoading = false;
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.filesError = e;
                    this.filesLoading = false;
                });
        },
        actionSoftDeleteFile(key) {
            this.files.splice(key, 1);
            this.$forceUpdate();

            this.actionGetStats();
        },
        actionCreate() {
            let http = axios.create();

            this.createLoading = true;
            http.post(this.apiUrl + '/manage-galleries', {
                name: this.nameFields[0].value,
            })
                .then((response) => {
                    this.hoveredId = response.data.id;
                    this.createError = null;
                    this.createLoading = false;

                    this.actionGet(response.data.id);
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.createError = e;
                    this.createLoading = false;
                });
        },
        actionUpdateName() {
            let http = axios.create();

            let folder = '';
            if (this.selectedFolder) {
                folder = '/folders/' + this.selectedFolder.name;
            }

            this.nameLoading = true;
            http.patch(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.selected.id +
                    folder +
                    '/name',
                {
                    name: this.nameFields[0].value,
                }
            )
                .then(() => {
                    this.hoveredId = this.selected.id;

                    if (this.selectedFolder) {
                        this.selectedFolder.name = this.nameFields[0].value;
                    } else {
                        this.selected.name = this.nameFields[0].value;
                    }

                    this.nameError = null;
                    this.nameSuccess = true;
                    this.nameLoading = false;
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.nameError = e;
                    this.nameSuccess = false;
                    this.nameLoading = false;
                });
        },
        actionCreateFolder() {
            this.triggerFolder++;
            for (let i = 0, j = this.folderFields.length; i < j; i++) {
                if (!this.folderFields[i].valid) {
                    return;
                }
            }

            let http = axios.create();

            this.folderLoading = true;
            http.post(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.selected.id +
                    '/folders',
                {
                    name: this.folderFields[0].value,
                }
            )
                .then(() => {
                    this.hoveredId = this.selected.id;

                    if (!this.selected.folders) {
                        this.selected.folders = [];
                    }

                    this.selected.folders.push({
                        name: this.folderFields[0].value,
                    });

                    this.folderFields[0].rules +=
                        ',' + this.folderFields[0].value;

                    this.folderFields[0].value = '';

                    this.folderError = null;
                    this.folderSuccess = true;
                    this.folderLoading = false;

                    UIkit.notification({
                        message: this.$t('folder.add.success'),
                        status: 'info',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.folderError = e;
                    this.folderSuccess = false;
                    this.folderLoading = false;

                    UIkit.notification({
                        message: this.$t('unexpected.error'),
                        status: 'error',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });
                });
        },
        actionUpdateArticleList() {
            let http = axios.create();

            this.articleListError = true;
            http.patch(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.selected.id +
                    '/article-list',
                {
                    articleListId: this.selected.articleListID,
                }
            )
                .then((response) => {
                    this.hoveredId = response.data.id;

                    this.articleListError = null;
                    this.articleListSuccess = true;
                    this.articleListLoading = false;
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.articleListError = e;
                    this.articleListSuccess = false;
                    this.articleListLoading = false;
                });
        },
        generateQRCodeSharelink() {
            const url =
                'https://phy-prints.com/qr_code_gen.php?domain=' +
                this.shareLink();
            window.open(url, '_blank').focus();
        },
        actionDisable() {
            let http = axios.create();

            let disableAt = null;
            if (this.withDisableDate) {
                disableAt = this.selected.disableAt;
            }

            this.disableLoading = true;
            http.patch(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.selected.id +
                    '/disabled',
                {
                    disabled: this.selected.disabled,
                    disableAt: disableAt,
                }
            )
                .then((response) => {
                    this.hoveredId = response.data.id;

                    this.disableError = null;
                    this.disableSuccess = true;
                    this.disableLoading = false;
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.disableError = e;
                    this.disableSuccess = false;
                    this.disableLoading = false;
                });
        },
        actionDisableCropping() {
            let http = axios.create();

            this.disableCroppingLoading = true;
            http.put(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.selected.id +
                    '/disable-cropping',
                {
                    disabled: this.selected.isCroppingDisabled,
                }
            )
                .then((response) => {
                    this.hoveredId = response.data.id;

                    this.disableCroppingError = null;
                    this.disableCroppingSuccess = true;
                    this.disableCroppingLoading = false;
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.disableCroppingError = e;
                    this.disableCroppingSuccess = false;
                    this.disableCroppingLoading = false;
                });
        },
        generateQRCodePublic() {
            const url =
                'https://phy-prints.com/qr_code_gen.php?domain=' + this.link();
            window.open(url, '_blank').focus();
        },
        actionUpdatePublic() {
            let http = axios.create();

            this.publicLoading = true;
            http.patch(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.selected.id +
                    '/is-public',
                {
                    isPublic: this.selected.isPublic,
                }
            )
                .then((response) => {
                    this.hoveredId = response.data.id;

                    this.publicError = null;
                    this.publicSuccess = true;
                    this.publicLoading = false;
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.publicError = e;
                    this.publicSuccess = false;
                    this.publicLoading = false;
                });
        },
        passwordSetValid(valid) {
            this.passwordSuccess = false;

            this.passwordValid = valid;
        },
        codeToClipboard() {
            this.copyToClipboard(this.selected.password);
        },
        resetPassword() {
            if (this.selected && this.selected.password.length < 6) {
                this.selected.password = this.generateRandomNumber(5) + '-';
            }
        },
        actionUpdatePassword() {
            let http = axios.create();

            this.passwordTrigger++;

            if (!this.passwordValid) {
                return;
            }

            this.passwordLoading = true;
            http.patch(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.selected.id +
                    '/password',
                {
                    password: this.selected.password,
                }
            )
                .then((response) => {
                    this.hoveredId = response.data.id;

                    this.passwordError = null;
                    this.passwordSuccess = true;
                    this.passwordLoading = false;

                    this.passwordDeleteSuccess = false;

                    this.selected.hasPassword = true;
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.passwordError = e;
                    this.passwordSuccess = false;
                    this.passwordLoading = false;
                });
        },
        actionDeletePassword() {
            let http = axios.create();

            this.passwordDeleteLoading = true;
            http.patch(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.selected.id +
                    '/password',
                {}
            )
                .then((response) => {
                    this.hoveredId = response.data.id;

                    this.passwordDeleteError = null;
                    this.passwordDeleteSuccess = true;
                    this.passwordDeleteLoading = false;

                    this.passwordSuccess = false;

                    this.selected.hasPassword = false;
                    this.selected.password = '';
                    this.passwordValid = false;
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.passwordDeleteError = e;
                    this.passwordDeleteSuccess = false;
                    this.passwordDeleteLoading = false;
                });
        },
        copyLink() {
            this.copyToClipboard(this.link());
        },
        copyShareLink() {
            this.copyToClipboard(this.shareLink());
        },
        link() {
            return (
                window.location.origin +
                '/' +
                this.$i18n.locale +
                '/galleries/' +
                this.selected.id
            );
        },
        shareLink() {
            return this.link() + '?token=' + this.selected.shareToken;
        },
        nameSetValid(valid, fieldKey) {
            this.nameSuccess = false;
            this.nameFields[fieldKey].valid = valid;
        },
        folderSetValid(valid, fieldKey) {
            this.folderSuccess = false;
            this.folderFields[fieldKey].valid = valid;
        },
        actionGetStats(statsOnly = false) {
            let http = axios.create();

            http.get(this.apiUrl + '/manage-galleries/stats')
                .then((response) => {
                    this.stats = response.data;
                    this.stats.sizeUsedInPercentage =
                        (this.stats.sizeUsageInBytes /
                            this.stats.sizeLimitInBytes) *
                        100.0;

                    if (!statsOnly) {
                        this.actionGet();
                    }
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.error = e;
                    this.loading = false;
                });
        },
        actionCreateShareLink() {
            let http = axios.create();
            this.shareLoading = true;

            http.post(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.selected.id +
                    '/share-token'
            )
                .then((response) => {
                    this.selected.shareToken = response.data.shareToken;

                    this.shareError = null;
                    this.shareSuccess = true;
                    this.shareLoading = false;
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.shareError = e;
                    this.shareSuccess = false;
                    this.shareLoading = false;
                });
        },
        actionDeleteShareLink() {
            let http = axios.create();
            this.shareLoading = true;

            http.delete(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.selected.id +
                    '/share-token'
            )
                .then(() => {
                    this.selected.shareToken = '';

                    this.shareError = null;
                    this.shareSuccess = true;
                    this.shareLoading = false;
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.shareError = e;
                    this.shareSuccess = false;
                    this.shareLoading = false;
                });
        },
        actionUpdated(item) {
            this.hoveredId = item.id;

            if (item.password) {
                item.hasPassword = true;
            }
            item.password = '';

            this.selected = item;

            if (this.selectedFolder) {
                for (let i = 0, j = this.selected.folders.length; i < j; i++) {
                    if (
                        this.selected.folders[i].name !==
                        this.selectedFolder.name
                    ) {
                        continue;
                    }

                    this.selectedFolder = this.selected.folders[i];
                }
            }

            this.actionGet();
        },
        actionGet(openId = '') {
            let http = axios.create();

            http.get(this.apiUrl + '/manage-galleries')
                .then((response) => {
                    this.galleries = response.data;

                    if (openId) {
                        for (let i = 0, j = this.galleries.length; i < j; i++) {
                            const gallery = this.galleries[i];

                            if (gallery.id === openId) {
                                this.selected = gallery;

                                if (this.selected.password) {
                                    this.selected.hasPassword = true;
                                }
                                this.selected.password = '';

                                UIkit.modal('#' + this.modalIdDetails).show();

                                break;
                            }
                        }
                    }

                    this.error = null;
                    this.loading = false;
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.error = e;
                    this.loading = false;
                });
        },
        actionReset() {
            this.files = [];
            this.pictures = [];

            this.nameSuccess = false;
            this.shareSuccess = false;
            this.publicSuccess = false;
            this.articleListSuccess = false;
            this.passwordSuccess = false;
            this.passwordDeleteSuccess = false;
            this.passwordValid = false;

            this.nameFields[0].value = '';
            this.nameFields[0].valid = false;

            UIkit.scroll('#modal-title', {offset: 0}).scrollTo('#modal-title');
        },
        actionNew() {
            this.actionReset();

            UIkit.modal('#' + this.modalIdCreate).show();
        },
        actionSelect(item) {
            this.actionReset();

            this.nameFields[0].value = item.name;
            this.nameFields[0].valid = true;

            this.selected = item;

            if (this.selected.password) {
                this.selected.hasPassword = true;
            }
            this.selected.password = '';

            if (this.selected.disableAt) {
                this.withDisableDate = true;
            }

            let names = [];
            if (item.folders) {
                for (let i = 0, j = item.folders.length; i < j; i++) {
                    names.push(item.folders[i].name);
                }
            }

            this.folderFields[0].rules += names.join(',');

            let self = this;
            setTimeout(function () {
                self.trigger++;
                UIkit.modal('#' + self.modalIdDetails).show();
                self.$forceUpdate();
            }, 10);

            if (!this.listenerInited) {
                let self = this;
                setTimeout(function () {
                    let element = document.getElementById('pictures-upload');
                    element.addEventListener('drop', self.dropListener, false);
                }, 50);

                this.listenerInited = true;
            }

            this.actionGetFiles();
        },
        actionDelete() {
            UIkit.modal('#' + this.modalIdDelete).show();
        },
        actionDeleteConfirmed() {
            this.deleteLoading = true;

            let folder = '';
            if (this.selectedFolder) {
                folder = '/folders/' + this.selectedFolder.name;
            }

            let http = axios.create();
            http.delete(
                this.apiUrl + '/manage-galleries/' + this.selected.id + folder
            )
                .then(() => {
                    this.$emit('updated');

                    UIkit.modal('#' + this.modalIdDelete).hide();

                    if (folder) {
                        this.folderFields[0].rules.replace(',' + folder, '');
                    }

                    UIkit.notification({
                        message: this.$t('gallery.deleted.successfully'),
                        status: 'info',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });

                    this.deleteLoading = false;

                    this.selectedFolder = null;

                    this.actionGetStats(false);
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    UIkit.notification({
                        message: this.$t('unexpected.error'),
                        status: 'error',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });

                    this.deleteLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.generated-title {
    text-transform: uppercase;
    font-size: 48px;
    font-weight: 500;
}

.thumbnail-height {
    height: 100px;
}

.uk-table th {
    white-space: nowrap;
}

.uk-progress {
    border-radius: 0 !important;
}

.uk-progress::-webkit-progress-value {
    background-color: $theme-background !important;
}

.uk-progress::-moz-progress-bar {
    background-color: $theme-background !important;
}

.dragover {
    border-color: $info-color;
}

.folder-name {
    margin-top: 3px;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.folder-sub {
    font-size: 15px;
}

.favicon-folder {
    max-width: 100px;
    max-height: 100px;
}
</style>
