import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import VueI18n from 'vue-i18n';
import messages from './translations';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import UKExtendedIcons from 'uikit-icons-extended/dist/js/uk-extended.min.js';
//import vueSeo from '@deveodk/vue-seo'
import VeeValidate, {Validator} from 'vee-validate';
import de from 'vee-validate/dist/locale/de';
import en from 'vee-validate/dist/locale/en';
import fr from 'vee-validate/dist/locale/fr';
import it from 'vee-validate/dist/locale/it';
import nl from 'vee-validate/dist/locale/nl';
import fi from 'vee-validate/dist/locale/fi';
//import HighchartsVue from 'highcharts-vue'
//import VueEllipseProgress from 'vue-ellipse-progress';
import VCalendar from 'v-calendar';

import VueCookie from 'vue-cookie';
import vueAwesomeCountdown from 'vue-awesome-countdown';
import UUID from 'vue-uuid';
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes';
//import VueSkeletonLoader from 'skeleton-loader-vue';

import VueCurrencyInput from 'vue-currency-input';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import money from 'v-money';
import Vue2TouchEvents from 'vue2-touch-events';
import {Cropper} from 'vue-advanced-cropper';

Vue.prototype.languages = [
    'de',
    'ch',
    'at',
    'li',
    'en',
    'it',
    'fr',
    'nl',
    'fi',
];
Vue.prototype.apiTimeout = 900000;
Vue.prototype.baseUrl = process.env.VUE_APP_BASE_URL || '';
Vue.prototype.apiUrl = process.env.VUE_APP_API_URL || '/api';
Vue.prototype.apiUrlTerminal = 'http://localhost:63178';
Vue.prototype.notificationTimeout = 3500;
Vue.prototype.cookieMaxExpiry = new Date(2147483647 * 1000);

Vue.use(VueI18n);
export const i18n = new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages,
});

dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

require('dayjs/locale/de');
require('dayjs/locale/de-at');
require('dayjs/locale/de-ch');
require('dayjs/locale/en');
require('dayjs/locale/fr');
require('dayjs/locale/it');
require('dayjs/locale/nl');
require('dayjs/locale/fi');

dayjs.updateLocale('de', {
    relativeTime: {
        future: 'in %s',
        past: 'vor %s',
        s: '%d Sekunden',
        m: '1 Minute',
        mm: '%d Minuten',
        h: '1 Stunde',
        hh: '%d Stunden',
        d: '1 Tag',
        dd: function (number, withoutSuffix) {
            if (withoutSuffix) {
                return number + ' Tage';
            }

            return number + ' Tagen';
        },
        M: '1 Monat',
        MM: function (number, withoutSuffix) {
            if (withoutSuffix) {
                return number + ' Monate';
            }

            return number + ' Monaten';
        },
        y: '1 Jahr',
        yy: function (number, withoutSuffix) {
            if (withoutSuffix) {
                return number + ' Jahre';
            }

            return number + ' Jahren';
        },
    },
});

dayjs.updateLocale('li', {
    relativeTime: {
        future: 'in %s',
        past: 'vor %s',
        s: '%d Sekunden',
        m: '1 Minute',
        mm: '%d Minuten',
        h: '1 Stunde',
        hh: '%d Stunden',
        d: '1 Tag',
        dd: function (number, withoutSuffix) {
            if (withoutSuffix) {
                return number + ' Tage';
            }

            return number + ' Tagen';
        },
        M: '1 Monat',
        MM: function (number, withoutSuffix) {
            if (withoutSuffix) {
                return number + ' Monate';
            }

            return number + ' Monaten';
        },
        y: '1 Jahr',
        yy: function (number, withoutSuffix) {
            if (withoutSuffix) {
                return number + ' Jahre';
            }

            return number + ' Jahren';
        },
    },
});

dayjs.updateLocale('at', {
    relativeTime: {
        future: 'in %s',
        past: 'vor %s',
        s: '%d Sekunden',
        m: '1 Minute',
        mm: '%d Minuten',
        h: '1 Stunde',
        hh: '%d Stunden',
        d: '1 Tag',
        dd: function (number, withoutSuffix) {
            if (withoutSuffix) {
                return number + ' Tage';
            }

            return number + ' Tagen';
        },
        M: '1 Monat',
        MM: function (number, withoutSuffix) {
            if (withoutSuffix) {
                return number + ' Monate';
            }

            return number + ' Monaten';
        },
        y: '1 Jahr',
        yy: function (number, withoutSuffix) {
            if (withoutSuffix) {
                return number + ' Jahre';
            }

            return number + ' Jahren';
        },
    },
});

dayjs.updateLocale('ch', {
    relativeTime: {
        future: 'in %s',
        past: 'vor %s',
        s: '%d Sekunden',
        m: '1 Minute',
        mm: '%d Minuten',
        h: '1 Stunde',
        hh: '%d Stunden',
        d: '1 Tag',
        dd: function (number, withoutSuffix) {
            if (withoutSuffix) {
                return number + ' Tage';
            }

            return number + ' Tagen';
        },
        M: '1 Monat',
        MM: function (number, withoutSuffix) {
            if (withoutSuffix) {
                return number + ' Monate';
            }

            return number + ' Monaten';
        },
        y: '1 Jahr',
        yy: function (number, withoutSuffix) {
            if (withoutSuffix) {
                return number + ' Jahre';
            }

            return number + ' Jahren';
        },
    },
});

window.UIkit = UIkit;
UIkit.use(Icons);

UIkit.use(UKExtendedIcons);

Vue.use(money, {precision: 2});
//Vue.use(vueSeo)

Vue.use(Vue2TouchEvents);

Vue.use(Cropper);

Validator.localize({de: de, en: en, fr: fr, it: it, nl: nl, fi: fi});
Vue.use(VeeValidate, {locale: 'de'});

//Vue.use(HighchartsVue)

//Vue.use(VueEllipseProgress);
Vue.use(VCalendar);
Vue.use(VueCookie);

Vue.use(vueAwesomeCountdown, 'vac');

Vue.use(UUID);

Vue.use(vueFilterPrettyBytes);

const pluginOptions = {
    globalOptions: {currency: 'EUR'},
};
Vue.use(VueCurrencyInput, pluginOptions);

//Vue.component('VueSkeletonLoader', VueSkeletonLoader);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
