<template>
    <div>
        <Modal :id="modalId"
               is-dark
               is-without-body
               is-without-close
               is-full>
            <div class="uk-position-relative uk-visible-toggle uk-modal-close"
                 tabindex="-1"
                 v-on:click="start"
                 uk-height-viewport
                 :id="slideshowId"
                 :uk-slideshow="getSlideShowSettings()">

                <ul class="uk-slideshow-items" uk-height-viewport>
                    <li v-for="(screensaver, key) in terminal.screensavers" :key="key">
                        <div class="uk-position-cover">

                            <video v-if="screensaver && isVideo(screensaver)"
                                   muted
                                   oncontextmenu="return false;"
                                   playsinline
                                   uk-cover>
                                <source :src="apiUrl+'/screensavers/'+screensaver"
                                        :type="'video/'+getVideoType(screensaver)">
                            </video>
                            <img v-else-if="screensaver" :src="apiUrl+'/screensavers/'+screensaver"
                                 class="uk-background-cover uk-animation-kenburns uk-animation-reverse" uk-cover>

                        </div>
                    </li>
                </ul>

                <div class="uk-position-bottom-center">
                    <div class="uk-margin-xlarge-bottom start-struct">
                        <Button
                            mode="blur"
                            is-huge
                            is-max
                        >
                            <Translate translation-key="start"/>
                        </Button>
                    </div>

                    <div class="uk-flex uk-flex-center uk-margin-bottom powered">
                        {{ terminal.screensaversText }}
                    </div>

                </div>

            </div>
        </Modal>
    </div>
</template>


<script>

import Modal from "@/components/generic/Modal.vue";
import UIkit from "uikit";
import Button from "@/components/generic/Button.vue";
import Translate from "@/components/Translate.vue";
import Vue from "vue";

export default {
    name: 'Screensaver',
    components: {Translate, Button, Modal},
    props: {
        terminal: {
            screensavers: Array,
            screensaversText: String,
            sliderInterval: Number,
            sliderVideoAdjusted: Boolean,
        },
    },
    data() {
        return {
            modalId: "modal-screensavers",
            slideshowId: "slideshow-screensavers",
        }
    },
    mounted() {
        this.showScreensaver();

        if (this.terminal.sliderVideoAdjusted) {
            const slideshow = UIkit.slideshow("#" + this.slideshowId);
            const slideshowElement = document.getElementById(this.slideshowId);
            slideshowElement.addEventListener('itemshow', (event) => {
                const element = event.target.children[0].children[0];
                const currentIndex = event.detail[0].index

                let nextIndex = currentIndex + 1;
                if (nextIndex > this.terminal.screensavers.length) {
                    nextIndex = 0;
                }

                if (element && element.tagName === 'VIDEO') {
                    slideshow.stopAutoplay();

                    element.addEventListener('ended', () => {
                        slideshow.show(nextIndex);
                        slideshow.startAutoplay();
                    });
                }
            });
        }

    },
    methods: {
        getSlideShowSettings() {
            let interval = this.terminal.sliderInterval * 1000;

            return "ratio: false; autoplay: true; autoplay-interval: " + interval + "; draggable: false; animation: push";
        },
        start() {
            this.$emit('start');
            this.$store.state.start = true;
        },
        showScreensaver() {
            if (!this.terminal.screensavers || this.terminal.screensavers.length === 0) {
                return;
            }

            if (!this.getInfos.terminal) {
                return;
            }

            const mode = Vue.localStorage.get('mode')
            if (mode !== "terminal") {
                return;
            }

            if (this.$route.name !== "start") {
                return;
            }

            UIkit.modal("#" + this.modalId).show();
        }
    }
}

</script>


<style lang="scss" scoped>

.start-struct {
    min-width: 300px;
    width: 25vw;
}

.uk-slideshow-items {
    opacity: 0.8;
}

.powered {
    font-size: $site-font-size-large;
    color: $site-color;
}

</style>
