var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{class:_vm.disabled ? 'module-disabled-struct' : '',attrs:{"uk-tooltip":_vm.disabled
            ? 'title:' + _vm.$t('x.module.disabled.hint', {x: _vm.$t('swifter')})
            : ''}},[_c('span',{class:_vm.disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'},[_c('Card',{staticClass:"uk-height-1-1",attrs:{"is-dark":"","mode":"default","id":"transfer-screensavers"}},[_c('LabelWithError',{attrs:{"is-label-large":"","error":_vm.inputError,"label":_vm.$t(_vm.title),"is-dark":""}}),_c('Description',[_c('Translate',{attrs:{"translation-key":"background.message"}})],1),_c('div',{staticClass:"uk-grid uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-1 uk-grid-match",attrs:{"uk-sortable":"handle: .uk-sortable-handle","id":'sortable-' + _vm.title,"uk-grid":""}},[_vm._l((_vm.fixed),function(screensaver,key){return _c('div',{key:'f' + key,attrs:{"id":'f' + key}},[_c('Modal',{attrs:{"id":'modal-fscreensaver-' + key,"is-without-body":"","is-close-outside":""}},[_c('div',{staticClass:"uk-text-center logo-form-struct"},[(_vm.isVideo(screensaver.id))?_c('video',{attrs:{"muted":"","oncontextmenu":"return false;","playsinline":"","autoplay":"","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":'https://update.phy-prints.com/swifter/' +
                                        screensaver.id,"type":'video/' +
                                        _vm.getVideoType(screensaver.id)}})]):_c('img',{attrs:{"src":'https://update.phy-prints.com/swifter/' +
                                    screensaver.id,"uk-img":""}})])]),_c('div',{staticClass:"uk-height-1-1"},[_c('div',{staticClass:"uk-position-relative logo-form-struct uk-text-center unified-height",attrs:{"id":'fscreensaver-upload-' + key}},[(_vm.isVideo(screensaver.id))?_c('video',{attrs:{"muted":"","oncontextmenu":"return false;","playsinline":"","loop":"","uk-video":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":'https://update.phy-prints.com/swifter/' +
                                        screensaver.id,"type":'video/' +
                                        _vm.getVideoType(screensaver.id)}})]):_c('img',{staticClass:"logo-form-item",attrs:{"src":'https://update.phy-prints.com/swifter/' +
                                    screensaver.id,"uk-img":""}})]),_c('div',{staticClass:"uk-card uk-card-body uk-padding-xsmall control secondary-with-border control-struct"},[_c('div',{staticClass:"uk-flex-center uk-flex uk-flex-middle",attrs:{"uk-grid":""}},[_c('div',[_c('ToggleItem',{attrs:{"toggle":'target: #modal-fscreensaver-' +
                                            key}},[_c('Icon',{staticClass:"cursor-pointer action-icon",attrs:{"ratio":1.3,"uk-tooltip":'title:' + _vm.$t('show'),"icon":"search"}})],1)],1)])])])],1)}),_vm._l((_vm.screensavers),function(screensaver,key){return _c('div',{key:key,attrs:{"id":key}},[_c('Modal',{attrs:{"id":'modal-tscreensaver-' + key,"is-without-body":"","is-close-outside":""}},[_c('div',{staticClass:"uk-text-center logo-form-struct"},[(_vm.input[key])?_c('span',[(_vm.isVideo(_vm.input[key]))?_c('video',{attrs:{"muted":"","oncontextmenu":"return false;","playsinline":"","autoplay":"","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.getLocalFileUrl(_vm.input[key]),"type":'video/' +
                                            _vm.getVideoType(screensaver)}})]):_c('img',{attrs:{"src":_vm.getLocalFileUrl(_vm.input[key]),"uk-img":""}})]):(screensaver)?_c('span',[(_vm.isVideo(screensaver))?_c('video',{attrs:{"muted":"","oncontextmenu":"return false;","playsinline":"","autoplay":"","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.apiUrl +
                                            '/transfer-screensavers/' +
                                            screensaver,"type":'video/' +
                                            _vm.getVideoType(screensaver)}})]):(screensaver)?_c('img',{attrs:{"src":_vm.apiUrl +
                                        '/transfer-screensavers/' +
                                        screensaver,"uk-img":""}}):_vm._e()]):_vm._e()])]),(_vm.input[key] || screensaver)?_c('div',{staticClass:"uk-height-1-1"},[_c('div',{staticClass:"uk-position-relative logo-form-struct uk-text-center unified-height",attrs:{"id":'tscreensaver-upload-' + key}},[(_vm.input[key])?_c('div',[(_vm.isVideo(_vm.input[key]))?_c('video',{attrs:{"muted":"","oncontextmenu":"return false;","playsinline":"","loop":"","uk-video":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.getLocalFileUrl(_vm.input[key]),"type":'video/' +
                                            _vm.getVideoType(screensaver)}})]):_c('img',{staticClass:"logo-form-item",attrs:{"src":_vm.getLocalFileUrl(_vm.input[key]),"uk-img":""}})]):(screensaver)?_c('div',[(_vm.isVideo(screensaver))?_c('video',{attrs:{"muted":"","oncontextmenu":"return false;","playsinline":"","loop":"","uk-video":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.apiUrl +
                                            '/transfer-screensavers/' +
                                            screensaver,"type":'video/' +
                                            _vm.getVideoType(screensaver)}})]):_c('img',{staticClass:"logo-form-item",attrs:{"src":_vm.apiUrl +
                                        '/transfer-screensavers/' +
                                        screensaver,"uk-img":""}})]):_c('div',{staticClass:"logo uk-light uk-flex uk-flex-middle uk-flex-center"},[_vm._v(" "+_vm._s(_vm.$t('no.x', {x: _vm.$t(_vm.title)}))+" ")])]),_c('div',{staticClass:"uk-card uk-card-body uk-padding-xsmall control secondary-with-border control-struct"},[_c('div',{staticClass:"uk-flex-center uk-flex uk-flex-middle",attrs:{"uk-grid":""}},[(false)?_c('div',[_c('Icon',{staticClass:"uk-sortable-handle cursor-pointer action-icon",attrs:{"ratio":1.3,"uk-tooltip":'title:' + _vm.$t('move'),"icon":"table"}})],1):_vm._e(),_c('div',[_c('span',{on:{"click":function($event){return _vm.triggerInputDialog(key)}}},[_c('Icon',{staticClass:"cursor-pointer action-icon",attrs:{"ratio":1.3,"uk-tooltip":'title:' +
                                                _vm.$t(
                                                    _vm.input[key] ||
                                                        screensaver
                                                        ? 'replace'
                                                        : 'add'
                                                ),"icon":"cloud-upload"}})],1),_c('input',{ref:'tscreensaver-' + key,refInFor:true,staticClass:"uk-hidden",attrs:{"id":'tscreensaver-' + key,"accept":'.' + _vm.fileExtensions.join(', .'),"type":"file"},on:{"click":function($event){_vm.$refs[
                                                'tscreensaver-' + key
                                            ].value = null},"change":function($event){return _vm.handleChange(key)}}})]),(_vm.input[key] || screensaver)?_c('div',[_c('ToggleItem',{attrs:{"toggle":'target: #modal-tscreensaver-' +
                                            key}},[_c('Icon',{staticClass:"cursor-pointer action-icon",attrs:{"ratio":1.3,"uk-tooltip":'title:' + _vm.$t('show'),"icon":"search"}})],1)],1):_vm._e(),(_vm.input[key] || screensaver)?_c('div',[_c('span',{on:{"click":function($event){return _vm.handleDelete(key)}}},[_c('Icon',{staticClass:"cursor-pointer action-icon",attrs:{"ratio":1.5,"uk-tooltip":'title:' + _vm.$t('delete'),"icon":"trash"}})],1)]):_vm._e()])])]):_c('div',{staticClass:"uk-height-1-1"},[_c('div',{staticClass:"uk-card uk-card-body uk-padding-xsmall control secondary-with-border uk-height-1-1"},[_c('div',{staticClass:"uk-flex-center uk-flex uk-flex-middle uk-flex-center uk-height-1-1 empty",attrs:{"uk-grid":""}},[_c('div',{staticClass:"screensaver-struct"},[_c('span',{on:{"click":function($event){return _vm.triggerInputDialog(key)}}},[_c('Icon',{staticClass:"cursor-pointer action-icon",attrs:{"ratio":1.3,"uk-tooltip":'title:' +
                                                _vm.$t(
                                                    _vm.input[key] ||
                                                        screensaver
                                                        ? 'replace'
                                                        : 'add'
                                                ),"icon":"cloud-upload"}})],1),_c('input',{ref:'tscreensaver-' + key,refInFor:true,staticClass:"uk-hidden",attrs:{"id":'tscreensaver-' + key,"accept":'.' + _vm.fileExtensions.join(', .'),"type":"file"},on:{"click":function($event){_vm.$refs[
                                                'tscreensaver-' + key
                                            ].value = null},"change":function($event){return _vm.handleChange(key)}}})])])])])],1)})],2),_c('div',{staticClass:"uk-margin-medium-bottom"}),_c('Button',{attrs:{"action":_vm.actionUpdate,"is-loading":_vm.loading,"text":_vm.$t('x.save', {x: _vm.$t(_vm.title)}),"has-action":"","is-large":"","is-max":"","mode":"primary"}}),(_vm.error)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('ErrorMessage',{attrs:{"error":_vm.error}}),(_vm.success)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('SuccessMessage',{attrs:{"success":_vm.success,"message":_vm.$t('x.changes.saved', {x: _vm.$t(_vm.title)})}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }