<template>
    <div>
        <LoadingStruct class="transfer-struct" is-dark>
            <Card
                is-round
                :title="$t('transfer.does.not.exist')"
                is-dark
                class="uk-text-left"
                mode="default"
                is-centered>
                <InfoBox class="uk-text-left" mode="danger">
                    <Translate
                        translation-key="transfer.does.not.exist.message" />
                </InfoBox>
            </Card>
        </LoadingStruct>
    </div>
</template>

<script>
import InfoBox from '@/components/generic/InfoBox.vue';
import Translate from '@/components/Translate.vue';
import Card from '@/components/generic/Card.vue';
import LoadingStruct from '@/views/LoadingStruct.vue';

export default {
    name: 'Transfers',
    components: {LoadingStruct, Card, Translate, InfoBox},
};
</script>

<style lang="scss" scoped>
.transfer-struct {
    max-width: 500px;
    min-height: calc(100vh - 217px);
}
</style>
