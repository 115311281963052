<template>
    <div>
        <div
            uk-grid="masonry: pack;"
            class="uk-grid-mini uk-child-width-1-6@l uk-child-width-1-5@m uk-child-width-1-4@s uk-child-width-1-2">
            <div
                v-for="(file, fileKey) in files"
                :key="file.path + file.name"
                v-on:click="actionSelectFile(file, fileKey)">
                <img
                    uk-img
                    class="cursor-pointer"
                    :width="file.width"
                    :height="file.height"
                    :src="
                        apiUrl +
                        '/galleries/' +
                        gallery.id +
                        (file.path ? '/folders/' + file.path : '') +
                        '/file-thumbnails/' +
                        file.name +
                        '?token=' +
                        token
                    "
                    :alt="file.name" />

                <Modal
                    is-transition-disabled
                    :id="modalIdShow + file.path + file.name"
                    is-extended
                    is-close-outside>
                    <div class="uk-grid" uk-grid>
                        <div class="uk-width-2-3@m uk-width-1-1">
                            <div class="uk-grid-collapse" uk-grid>
                                <div
                                    class="uk-width-auto uk-flex uk-flex-middle">
                                    <span v-on:click="actionNextPicture(-1)">
                                        <Icon
                                            :ratio="2"
                                            :tooltip="$t('previous.picture')"
                                            class="cursor-pointer"
                                            icon="chevron-left" />
                                    </span>
                                </div>
                                <div class="uk-width-expand">
                                    <div
                                        class="uk-transition-toggle image-struct">
                                        <div
                                            class="uk-position-relative cropper-struct2 uk-flex-center uk-text-center">
                                            <img
                                                v-if="!croppingEnabled"
                                                :class="
                                                    isCroppingDisabled
                                                        ? ''
                                                        : 'cropper-disabled'
                                                "
                                                v-on:mouseover="
                                                    addHighlight(false)
                                                "
                                                v-on:mouseout="
                                                    removeHighlight()
                                                "
                                                v-on:click="addHighlight(true)"
                                                :uk-tooltip="
                                                    'title:' +
                                                    (isCroppingDisabled
                                                        ? ''
                                                        : $t(
                                                              'crop.disabled.message',
                                                              {
                                                                  symbol: '<span uk-icon=\'icon: move\'></span>',
                                                              }
                                                          ))
                                                "
                                                :width="file.width"
                                                :height="file.height"
                                                :src="
                                                    apiUrl +
                                                    '/galleries/' +
                                                    gallery.id +
                                                    (file.path
                                                        ? '/folders/' +
                                                          file.path
                                                        : '') +
                                                    '/file-thumbnails/' +
                                                    file.name +
                                                    '?token=' +
                                                    token
                                                " />

                                            <cropper
                                                v-if="shows[getFileID(file)]"
                                                style="min-height: 100px"
                                                :width="file.width"
                                                :height="file.height"
                                                :src="
                                                    apiUrl +
                                                    '/galleries/' +
                                                    gallery.id +
                                                    (file.path
                                                        ? '/folders/' +
                                                          file.path
                                                        : '') +
                                                    '/file-thumbnails/' +
                                                    file.name +
                                                    '?token=' +
                                                    token
                                                "
                                                :debounce="600"
                                                :moveImage="true"
                                                :auto-zoom="false"
                                                :transitions="true"
                                                :resizeImage="{
                                                    wheel: true,
                                                    adjustStencil: true,
                                                    touch: true,
                                                }"
                                                :ref="
                                                    'cropper' +
                                                    file.path +
                                                    file.name
                                                "
                                                :id="
                                                    'cropper' +
                                                    file.path +
                                                    file.name
                                                "
                                                :default-size="{
                                                    width: 999999999,
                                                    height: 999999999,
                                                }"
                                                :class="
                                                    'cropper uk-width-1-1' +
                                                    (croppingEnabled
                                                        ? ''
                                                        : ' cropper-item-disabled') +
                                                    (isCroppingDisabled
                                                        ? ' moved-invisible'
                                                        : '')
                                                "
                                                :stencil-props="{
                                                    aspectRatio:
                                                        getCropperRatioByPictureKey(
                                                            file
                                                        ),
                                                    class: 'cropper-stencil',
                                                    previewClass:
                                                        'cropper-stencil__preview',
                                                    draggingClass:
                                                        'cropper-stencil--dragging',
                                                    handlersClasses: {
                                                        default:
                                                            'cropper-handler',
                                                        eastNorth:
                                                            'cropper-handler--east-north',
                                                        westNorth:
                                                            'cropper-handler--west-north',
                                                        eastSouth:
                                                            'cropper-handler--east-south',
                                                        westSouth:
                                                            'cropper-handler--west-south',
                                                    },
                                                    handlersWrappersClasses: {
                                                        default:
                                                            'cropper-handler-wrapper',
                                                        eastNorth:
                                                            'cropper-handler-wrapper--east-north',
                                                        westNorth:
                                                            'cropper-handler-wrapper--west-north',
                                                        eastSouth:
                                                            'cropper-handler-wrapper--east-south',
                                                        westSouth:
                                                            'cropper-handler-wrapper--west-south',
                                                    },
                                                }" />
                                        </div>

                                        <div
                                            v-if="!isCroppingDisabled"
                                            class="uk-card uk-card-body uk-padding-xsmall control secondary-with-border">
                                            <div
                                                class="uk-flex-center uk-flex"
                                                uk-grid>
                                                <span
                                                    v-if="croppingEnabled"
                                                    v-on:click="
                                                        disableCropping()
                                                    "
                                                    class="action-icon">
                                                    <Icon
                                                        :ratio="1.3"
                                                        :tooltip="
                                                            $t('crop.disable')
                                                        "
                                                        class="cursor-pointer uk-form-warning"
                                                        icon="move" />
                                                </span>

                                                <span
                                                    v-else
                                                    v-on:click="
                                                        enableCropping()
                                                    "
                                                    class="action-icon">
                                                    <Icon
                                                        :ratio="1.3"
                                                        :tooltip="
                                                            $t('crop.enable')
                                                        "
                                                        :id="'crop-icon'"
                                                        class="cursor-pointer"
                                                        icon="move" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="uk-width-auto uk-flex uk-flex-middle">
                                    <span v-on:click="actionNextPicture(+1)">
                                        <Icon
                                            :ratio="2"
                                            :tooltip="$t('next.picture')"
                                            class="cursor-pointer"
                                            icon="chevron-right" />
                                    </span>
                                </div>
                            </div>

                            <div class="picture-name uk-text-center">
                                {{ file.name }}
                            </div>
                        </div>

                        <div class="uk-width-1-3@m uk-width-1-1">
                            <div v-if="!unifiedFormat">
                                <div
                                    class="uk-grid-small uk-child-width-1-1"
                                    uk-grid>
                                    <div
                                        v-for="format in getFormats"
                                        :key="'f' + format">
                                        <div
                                            v-on:click="
                                                actionSelectFormat(format, file)
                                            ">
                                            <LabelSelect
                                                :is-selected="
                                                    format === unifiedFormat
                                                ">
                                                <div
                                                    class="uk-text-center paper-text">
                                                    {{ format }}
                                                </div>
                                            </LabelSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-else>
                                <div v-on:click="actionResetFormat()">
                                    <LabelSelect>
                                        <div class="uk-text-center paper-text">
                                            <Translate
                                                translation-key="back.to.formats" />
                                        </div>
                                    </LabelSelect>
                                </div>
                                <hr />

                                <LabelSelect is-muted is-selected>
                                    <div class="uk-text-center paper-text">
                                        {{ unifiedFormat }}
                                    </div>
                                </LabelSelect>

                                <div class="uk-margin-top" />
                                <div
                                    class="uk-height-1-1 uk-grid-small uk-child-width-1-2@l uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match"
                                    uk-grid>
                                    <div
                                        v-for="paper in getPapersByFormat(
                                            unifiedFormat
                                        )"
                                        :key="paper.value">
                                        <div
                                            v-on:click="
                                                actionSelectPaper(paper)
                                            ">
                                            <LabelSelect
                                                :is-selected="
                                                    paper.value === unifiedId
                                                ">
                                                <div
                                                    class="uk-text-center paper-text">
                                                    {{
                                                        getPaperName(paper.text)
                                                    }}
                                                </div>
                                                <div
                                                    class="uk-text-center paper-price">
                                                    {{ paper.price }}

                                                    <div v-if="paper.minPrice">
                                                        {{ paper.minPrice }}
                                                        {{
                                                            $t('minimum.price')
                                                        }}
                                                    </div>
                                                </div>
                                            </LabelSelect>
                                        </div>
                                    </div>
                                </div>

                                <div class="uk-margin-top" />
                                <div class="uk-grid-collapse" uk-grid>
                                    <div class="uk-width-auto">
                                        <span v-on:click="manipulateAmount(-1)">
                                            <Button
                                                mode="default"
                                                with-class="minus-button">
                                                <Icon icon="minus" />
                                            </Button>
                                        </span>
                                    </div>
                                    <div
                                        class="uk-width-expand uk-text-center item-amount">
                                        <input
                                            v-model.number="amount"
                                            v-on:input="validateAmount()"
                                            :placeholder="$t('amount')"
                                            min="1"
                                            class="uk-input uk-text-center"
                                            type="number" />
                                    </div>
                                    <div class="uk-width-auto">
                                        <span v-on:click="manipulateAmount(+1)">
                                            <Button
                                                mode="default"
                                                with-class="plus-button">
                                                <Icon icon="plus" />
                                            </Button>
                                        </span>
                                    </div>
                                </div>

                                <div class="uk-margin-top" />
                                <div
                                    v-if="
                                        getArticleByID(unifiedId) &&
                                        getArticleByID(unifiedId)
                                            .graduatedPrices &&
                                        getArticleByID(unifiedId)
                                            .graduatedPrices.length > 0
                                    "
                                    class="uk-margin-top">
                                    <div class="" />

                                    <InfoBox class="graduated-prices">
                                        <div
                                            v-for="(
                                                graduatedPrice, key
                                            ) in getArticleByID(unifiedId)
                                                .graduatedPrices"
                                            :key="key">
                                            <span
                                                v-if="
                                                    graduatedPrice.amount -
                                                        getGroupedPicturesAmountByArticleID(
                                                            unifiedId
                                                        ) >
                                                    0
                                                ">
                                                {{
                                                    $t('x.at.x.amount.left.x', {
                                                        price:
                                                            getNumberWithCurrency(
                                                                graduatedPrice.price
                                                            ) +
                                                            (getArticleByID(
                                                                unifiedId
                                                            ).isHeightVariable
                                                                ? ' ' +
                                                                  $t('per.x', {
                                                                      x: $t(
                                                                          getInfos.measurementUnit
                                                                      ),
                                                                  })
                                                                : ''),
                                                        amount: graduatedPrice.amount,
                                                        required:
                                                            graduatedPrice.amount -
                                                            getGroupedPicturesAmountByArticleID(
                                                                unifiedId
                                                            ),
                                                    })
                                                }}
                                            </span>
                                            <span v-else>
                                                {{
                                                    $t('x.at.x.amount', {
                                                        price: getNumberWithCurrency(
                                                            graduatedPrice.price
                                                        ),
                                                        amount: graduatedPrice.amount,
                                                    })
                                                }}

                                                <Icon
                                                    :ratio="0.8"
                                                    icon="check" />
                                            </span>
                                        </div>
                                    </InfoBox>
                                </div>

                                <div class="uk-margin-medium-top" />
                                <span v-on:click="actionAdd(file)">
                                    <Button mode="theme" is-max is-large>
                                        <Icon icon="plus" />
                                        <Translate translation-key="add" />
                                    </Button>
                                </span>
                            </div>
                        </div>

                        <div class="uk-width-1-1">
                            <Button
                                is-large
                                is-max
                                has-action
                                :action="actionOrderView"
                                mode="secondary">
                                {{ samples }}
                                <Icon icon="cart" />

                                <span class="uk-margin-small-left" />
                                <Translate translation-key="cart" />

                                {{ total }}
                            </Button>
                        </div>

                        <div class="uk-width-1-1">
                            <Button
                                class="uk-modal-close"
                                is-max
                                is-large
                                mode="default">
                                <Translate translation-key="close" />
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/generic/Icon.vue';
import UIkit from 'uikit';
import Modal from '@/components/generic/Modal.vue';
import LabelSelect from '@/components/LabelSelect.vue';
import Translate from '@/components/Translate.vue';
import InfoBox from '@/components/generic/InfoBox.vue';
import Button from '@/components/generic/Button.vue';
import Vue from 'vue';

export default {
    name: 'GalleryPublicFile',
    components: {Button, InfoBox, Translate, LabelSelect, Modal, Icon},
    props: {
        triggerShow: Number,

        selectedFiles: Array,
        files: Array,
        total: String,
        samples: Number,
        modalIdOrderView: String,

        token: {
            type: String,
            default: '',
        },

        gallery: {
            id: String,
            name: String,
            thumbnail: String,
            articleListID: [
                {
                    id: String,
                    paper: String,
                    width: Number,
                    height: Number,
                    price: Number,
                    graduatedPrices: [
                        {
                            amount: Number,
                            price: Number,
                        },
                    ],
                    isFavorite: Boolean,
                    isHeightVariable: Boolean,
                    dpi: Number,
                    name: String,
                    is: Boolean,
                },
            ],
            folders: [
                {
                    name: String,
                    thumbnail: String,
                },
            ],
            stats: {
                sizeUsageInBytes: Number,
                pictures: Number,
                folder: Number,
            },
        },

        file: {
            name: String,
            isDir: Boolean,
            path: String,

            width: Number,
            height: Number,
        },

        folder: Object,
    },
    data() {
        return {
            modalIdShow: 'modal-gallery-file',

            unifiedFormat: '',
            unifiedId: '',
            unifiedPaper: '',
            amount: 1,
            coordinates: null,

            shows: [],
            croppingEnabled: false,

            currentIndex: 0,
        };
    },
    computed: {
        isCroppingDisabled() {
            return this.gallery.isCroppingDisabled;
        },
        getFormats() {
            let formats = [];

            let articles = this.gallery.articleListID;

            if (!articles) {
                return formats;
            }

            articles = articles.slice().sort(this.articleFormatSortFunc);

            for (let i = 0, j = articles.length; i < j; i++) {
                let format = this.getArticleFormat(articles[i]);
                if (!formats.includes(format)) {
                    formats.push(format);
                }
            }

            return formats;
        },
    },
    mounted() {
        let self = this;

        for (let i = 0, j = this.files.length; i < j; i++) {
            const file = this.files[i];
            const targetId = this.modalIdShow + file.path + file.name;
            const modalElement = document.getElementById(targetId);

            modalElement.addEventListener('show', function () {
                self.$refs['cropper' + file.path + file.name][0].setCoordinates(
                    [
                        ({imageSize}) => ({
                            width: imageSize.width,
                            height: imageSize.height,
                        }),
                        ({coordinates, imageSize}) => ({
                            left: imageSize.width / 2 - coordinates.width / 2,
                            top: imageSize.height / 2 - coordinates.height / 2,
                        }),
                    ]
                );
            });
        }
    },
    methods: {
        actionNextPicture(modifier) {
            const maxLen = this.files.length - 1;
            let targetIndex = this.currentIndex + modifier;

            if (targetIndex < 0) {
                targetIndex = maxLen;
            }

            if (targetIndex > maxLen) {
                targetIndex = 0;
            }

            this.actionSelectFile(this.files[targetIndex], targetIndex);
        },
        enableCropping() {
            this.croppingEnabled = true;
        },
        disableCropping() {
            this.croppingEnabled = false;
        },
        addHighlight(clicked) {
            let id = 'crop-icon';
            let element = document.getElementById(id);
            if (!element) {
                return;
            }

            element.classList.add('crop-icon-hovered');

            if (clicked) {
                setTimeout(function () {
                    element.classList.remove('crop-icon-hovered');
                }, 1000);
            }
        },
        removeHighlight() {
            let id = 'crop-icon';
            let element = document.getElementById(id);
            if (!element) {
                return;
            }

            element.classList.remove('crop-icon-hovered');
        },
        actionOrderView() {
            UIkit.modal('#' + this.modalIdOrderView).show();
        },
        manipulateAmount(manipulator) {
            this.amount += manipulator;
            this.validateAmount();
            this.$forceUpdate();
        },
        validateAmount() {
            if (!this.amount || this.amount === 0) {
                this.amount = 1;
            }

            if (this.amount < 0) {
                this.amount *= -1;
            }
        },
        actionAdd(file) {
            const {coordinates, canvas} =
                this.$refs['cropper' + file.path + file.name][0].getResult();

            let self = this;
            canvas.toBlob(
                (blob) => {
                    self.$emit(
                        'add',
                        file,
                        self.unifiedId,
                        self.amount,
                        coordinates,
                        self.getLocalFileUrl(blob)
                    );

                    UIkit.notification({
                        message: self.$t('picture.added.to.cart'),
                        status: 'info',
                        pos: 'top-center',
                        timeout: self.notificationTimeout,
                    });

                    self.actionResetFormat();
                },
                'image/png',
                1.0
            );
        },
        actionSelectPaper(paper) {
            this.unifiedId = paper.value;
            this.unifiedPaper = paper.text;

            this.$forceUpdate();
        },
        actionSelectFormat(format, file) {
            this.unifiedFormat = format;
            this.unifiedId = this.getFirstArticleIDByFormat(format);

            this.$forceUpdate();

            this.$refs['cropper' + file.path + file.name][0].setCoordinates([
                ({imageSize}) => ({
                    width: imageSize.width,
                    height: imageSize.height,
                }),
                ({coordinates, imageSize}) => ({
                    left: imageSize.width / 2 - coordinates.width / 2,
                    top: imageSize.height / 2 - coordinates.height / 2,
                }),
            ]);
        },
        getPapersByFormat(format) {
            let papers = [];

            let articles = this.gallery.articleListID;
            for (let i = 0, j = articles.length; i < j; i++) {
                let rowFormat = this.getArticleFormat(articles[i]);

                if (format && format !== rowFormat) {
                    continue;
                }

                let priceString = this.getNumberWithCurrency(articles[i].price);
                if (articles[i].isHeightVariable) {
                    priceString +=
                        ' ' +
                        this.$t('per.x', {
                            x: this.$t(this.getInfos.measurementUnit),
                        });
                }

                let minPriceString = null;
                if (articles[i].isHeightVariable && articles[i].minPrice) {
                    minPriceString = this.getNumberWithCurrency(
                        articles[i].minPrice
                    );
                }

                papers.push({
                    value: articles[i].id,
                    text: articles[i].paper,
                    price: priceString,
                    minPrice: minPriceString,
                });
            }

            return papers;
        },
        actionResetFormat() {
            this.unifiedFormat = '';
            this.unifiedId = '';
            this.unifiedPaper = '';
            this.amount = 1;
            this.coordinates = null;

            this.$forceUpdate();
        },
        getFavoriteArticle() {
            let articles = this.gallery.articleListID;

            if (articles) {
                for (let i = 0, j = articles.length; i < j; i++) {
                    if (articles[i].isFavorite) {
                        return articles[i];
                    }
                }
            }

            return null;
        },
        getFirstArticleIDByFormat(format, paper) {
            if (!format) {
                if (!this.gallery.articleListID) {
                    return 0;
                }

                let favoriteArticle = this.getFavoriteArticle();
                if (favoriteArticle) {
                    return favoriteArticle.id;
                }

                return this.gallery.articleListID[0].id;
            }

            let articles = this.gallery.articleListID;

            articles = articles.sort(
                (a, b) => Number(b.isFavorite) - Number(a.isFavorite)
            );

            articles = articles.sort(this.articleFormatSortFunc);

            if (paper) {
                paper = this.getPaperName(paper);

                for (let i = 0, j = articles.length; i < j; i++) {
                    let parsedFormat = this.getArticleFormat(articles[i]);
                    if (
                        format === parsedFormat &&
                        paper === this.getPaperName(articles[i].paper)
                    ) {
                        return articles[i].id;
                    }
                }
            }

            for (let i = 0, j = articles.length; i < j; i++) {
                let parsedFormat = this.getArticleFormat(articles[i]);
                if (format === parsedFormat) {
                    return articles[i].id;
                }
            }
        },
        getArticleFormat(article) {
            if (article.name) {
                return article.name;
            }

            if (article.isHeightVariable) {
                return (
                    this.roundWidth(article.width) +
                    ' ' +
                    this.$t(this.getInfos.measurementUnit) +
                    ' x ' +
                    this.$t('pictureLength')
                );
            }

            return (
                this.roundWidth(article.width) +
                ' x ' +
                this.roundHeight(article.height) +
                ' ' +
                this.$t(this.getInfos.measurementUnit)
            );
        },
        articleFormatSortFunc(a, b) {
            if (a.width < b.width) return -1;
            if (a.width > b.width) return 1;
            return 0;
        },
        getArticleByID(articleID) {
            let articles = this.gallery.articleListID;
            for (let i = 0, j = articles.length; i < j; i++) {
                if (articles[i].id === articleID) {
                    return articles[i];
                }
            }
        },
        getGroupedPicturesAmountByArticleID(articleID) {
            let amount = 0;

            let pictures = this.selectedFiles;
            for (let i = 0, j = pictures.length; i < j; i++) {
                if (this.unifiedId === articleID) {
                    amount += pictures[i].amount;
                }
            }

            amount += this.amount;

            return amount;
        },
        getCropperRatioByPictureKey(file) {
            if (this.isCroppingDisabled) {
                return null;
            }

            const item = file;
            const article = this.getArticleByID(this.unifiedId);

            if (!article) {
                return null;
            }

            if (article.isHeightVariable) {
                return null;
            }

            // hochformat
            if (item.height > item.width) {
                if (article.height > article.width) {
                    return article.width / article.height;
                }

                return article.height / article.width;
            }

            // querformat
            if (article.height > article.width) {
                return article.height / article.width;
            }

            return article.width / article.height;
        },
        areCoordinatesEqual(oldCoordinates, newCoordinates) {
            if (!oldCoordinates) {
                return false;
            }

            if (oldCoordinates.height !== newCoordinates.height) {
                return false;
            }

            if (oldCoordinates.left !== newCoordinates.left) {
                return false;
            }

            if (oldCoordinates.top !== newCoordinates.top) {
                return false;
            }

            if (oldCoordinates.width !== newCoordinates.width) {
                return false;
            }

            return true;
        },
        actionSelectFile(file, targetIndex) {
            this.currentIndex = targetIndex;

            const fileID = this.getFileID(file);
            Vue.set(this.shows, fileID, true);

            const modalElement = document.getElementById(
                this.modalIdShow + fileID
            );
            UIkit.modal(modalElement).show();

            this.$forceUpdate();
        },
        getFileID(file) {
            return file.path + file.name;
        },
    },
};
</script>

<style lang="scss" scoped>
.name {
    color: #000 !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 4px;
}

.cropper {
    background: #000;
}

.cropper-struct2 {
    padding: 15px;
    background: #111;
}

.modal-struct {
    height: 80vh;
}

.picture-name {
    font-size: 16px;
    margin-top: 10px;
}

.paper-text {
    font-weight: 500;
    word-break: break-all;
    padding: 5px;
}

.thumbnail {
    height: 425px;
    color: #fff;
}

.scrollable {
    overflow: auto;
}

.overlay-fix {
    margin-left: 15px !important;
}

.uk-grid-mini,
.uk-grid-column-small {
    margin-left: -5px;
}

.uk-grid-mini > *,
.uk-grid-column-small > * {
    padding-left: 5px;
}

/* Vertical */
.uk-grid + .uk-grid-mini,
.uk-grid + .uk-grid-row-small,
.uk-grid-mini > .uk-grid-margin,
.uk-grid-row-small > .uk-grid-margin,
* + .uk-grid-margin-small {
    margin-top: 5px;
}

.cropper-disabled {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    opacity: 0;
    border: 15px solid transparent;
    width: 100%;
    height: 100%;
}

.action-icon {
    color: $site-color;

    &:hover {
        color: $site-color-hover;
    }
}

.moved-invisible {
    display: block !important;
    position: fixed !important;
    top: -999999px !important;
    left: -999999px !important;
}
</style>
