var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"blank-struct"}),_c('LoadingStruct',{staticClass:"transfer-struct",attrs:{"is-dark":"","is-loading":_vm.loading,"error":_vm.error}},[(
                _vm.transfer && _vm.transfer.transfer && _vm.transfer.transfer.expiresAt
            )?_c('countdown',{staticClass:"uk-hidden",attrs:{"end-time":new Date(_vm.transfer.transfer.expiresAt).getTime()},on:{"finish":_vm.reload}}):_vm._e(),(_vm.error && _vm.error.message.includes('404'))?_c('Card',{staticClass:"uk-text-left",attrs:{"is-round":"","title":_vm.$t('transfer.does.not.exist'),"is-dark":"","mode":"default","is-centered":""}},[_c('InfoBox',{staticClass:"uk-text-left",attrs:{"mode":"danger"}},[_c('Translate',{attrs:{"translation-key":"transfer.does.not.exist.message"}})],1)],1):_c('Card',{attrs:{"is-round":"","title":_vm.transfer.transfer.name,"is-dark":"","mode":"default","is-centered":""}},[_c('a',{attrs:{"uk-tooltip":'title:' +
                    _vm.$t(
                        _vm.disabledDownload
                            ? 'transfer.available.shortly'
                            : 'download'
                    ),"href":_vm.disabledDownload
                        ? '#'
                        : _vm.apiUrl +
                          '/transfers/' +
                          _vm.transferToken +
                          '/' +
                          _vm.transfer.transfer.name +
                          '?session=' +
                          _vm.transfer.token}},[_c('Button',{attrs:{"is-disabled":_vm.disabledDownload,"is-round":"","is-large":"","is-min-width":"","mode":"success","is-loading":_vm.loadingDownload}},[(_vm.disabledDownload)?_c('countdown',{attrs:{"end-time":new Date(_vm.transfer.accessibleAt).getTime()},on:{"finish":_vm.unlockDownload},scopedSlots:_vm._u([{key:"process",fn:function(ref){
                          var timeObj = ref.timeObj;
return _c('span',{},[_vm._v(" "+_vm._s(timeObj.ceil.s)+" ")])}}],null,false,4147121750)}):_c('Icon',{attrs:{"icon":"cloud-download","ratio":1.4}})],1)],1),_c('div',{staticClass:"uk-margin-small-top"},[_vm._v(" "+_vm._s(_vm.getParsedBytes(_vm.transfer.transfer.sizeInBytes))+" ")]),(_vm.transfer.transfer.message)?_c('div',{staticClass:"uk-margin-medium-top uk-text-left word-struct"},[_c('InfoBox',{staticClass:"uk-text-center",attrs:{"borderless":"","slim":"","is-round":"","mode":"blank"}},[_vm._v(" "+_vm._s(_vm.transfer.transfer.message)+" ")])],1):_vm._e(),_c('div',{staticClass:"uk-margin-medium-top word-struct"},[_c('InfoBox',{attrs:{"borderless":"","mode":"blank","slim":"","is-round":""}},[_c('span',{attrs:{"uk-tooltip":'title:' +
                            _vm.$t('On') +
                            ' ' +
                            _vm.getFormattedDateTimeByDateString(
                                _vm.transfer.transfer.expiresAt
                            ) +
                            ' ' +
                            _vm.$t('clock')}},[_c('span',{staticClass:"expires-at-text"},[_c('Translate',{attrs:{"translation-key":"expires.at"}}),_vm._v(" "+_vm._s(_vm.getDaysLeftByDateString( _vm.transfer.transfer.expiresAt, false ))+" ")],1)])])],1),_c('div',{staticClass:"uk-margin-top"},[_c('div',{staticClass:"transfer-report"},[_c('a',{staticClass:"uk-link-muted",attrs:{"target":"_blank","href":_vm.getReportURL()}},[_c('Translate',{attrs:{"translation-key":"transfer.report"}})],1)]),_c('Translate',{staticClass:"transfer-report-message",attrs:{"translation-key":"transfer.report.message"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }