<template>
    <div class="uk-card uk-card-default uk-card-hover">
        <div
            v-if="item.isDir"
            :uk-tooltip="'title:' + item.name"
            class="uk-height-small uk-text-center folder-struct uk-padding-small uk-flex-middle uk-transition-toggle">
            <Icon icon="folder" :ratio="2.5" />

            <div class="action-name uk-margin-small-top folder-name">
                {{ item.name }}
            </div>

            <div
                class="uk-transition-fade uk-overlay uk-overlay-primary uk-position-cover">
                <div
                    class="uk-flex uk-flex-middle uk-flex-center uk-height-1-1">
                    <span v-on:click="actionOpenFolder()">
                        <Icon
                            class="cursor-pointer"
                            icon="folder"
                            :uk-tooltip="'title:' + $t('folder.open')"
                            :ratio="1.5" />
                    </span>

                    <span
                        class="uk-margin-small-left"
                        v-on:click="actionChangeThumbnail()">
                        <Icon
                            class="cursor-pointer"
                            icon="image"
                            :uk-tooltip="'title:' + $t('thumbnail.change')"
                            :ratio="1.5" />
                    </span>

                    <span
                        class="uk-margin-small-left"
                        v-on:click="actionDeleteFolder()">
                        <Icon
                            class="cursor-pointer"
                            icon="trash"
                            :uk-tooltip="'title:' + $t('delete')"
                            :ratio="1.5" />
                    </span>
                </div>
            </div>
        </div>

        <div v-else>
            <div
                :uk-tooltip="'title:' + item.name"
                class="uk-cover-container uk-transition-toggle uk-height-small">
                <div class="uk-flex uk-flex-center uk-flex-middle image-item">
                    <img
                        class="image-item-struct"
                        :src="
                            apiUrl +
                            '/manage-galleries/' +
                            galleryId +
                            (folder ? '/folders/' + folder.name : '') +
                            '/file-thumbnails/' +
                            item.name +
                            '?token=' +
                            getToken
                        "
                        :alt="item.name"
                        uk-img />
                </div>
                <div
                    class="uk-transition-fade uk-overlay uk-overlay-primary uk-position-cover">
                    <div
                        class="uk-flex uk-flex-middle uk-flex-center uk-height-1-1">
                        <span v-if="thumbnailingLoading" uk-spinner />
                        <span v-else v-on:click="actionSetThumbnail()">
                            <Icon
                                class="cursor-pointer"
                                icon="image"
                                :uk-tooltip="'title:' + $t('set.as.thumbnail')"
                                :ratio="1.5" />
                        </span>

                        <span class="uk-margin-small-left" />
                        <span v-on:click="actionDeleteFile()">
                            <span v-if="deleteLoading" uk-spinner />
                            <Icon
                                v-else
                                class="cursor-pointer"
                                icon="trash"
                                :uk-tooltip="'title:' + $t('delete')"
                                :ratio="1.5" />
                        </span>
                    </div>
                </div>
            </div>

            <div :uk-tooltip="'title:' + item.name" class="name uk-text-center">
                <Icon
                    :uk-tooltip="'title:' + $t('thumbnail')"
                    icon="image"
                    class="uk-margin-small-right"
                    v-if="isThumbnail" />

                {{ item.name }}
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/generic/Icon.vue';
import axios from 'axios';
import UIkit from 'uikit';

export default {
    name: 'GalleryFile',
    components: {Icon},
    props: {
        galleryId: String,
        item: {
            name: String,
            isDir: Boolean,
            path: String,
        },
        itemKey: Number,

        folder: Object,

        isThumbnail: Boolean,
    },
    data() {
        return {
            thumbnailingLoading: false,
            deleteLoading: false,
        };
    },
    methods: {
        actionSetThumbnail() {
            let http = axios.create();

            let path = '';
            if (this.item.path !== '/' && this.item.path !== '') {
                path = '/folders/' + this.item.path;
            }

            // this.thumbnailingLoading = true;
            http.post(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.galleryId +
                    path +
                    '/files/' +
                    this.item.name +
                    '/actions/make-thumbnail'
            )
                .then((response) => {
                    this.$emit('updated', response.data);
                    this.thumbnailingLoading = false;

                    UIkit.notification({
                        message: this.$t('x.set.thumbnail', {
                            x: this.item.name,
                        }),
                        status: 'success',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });
                })
                .catch((e) => {
                    this.checkGenericError(e);
                    this.thumbnailingLoading = false;
                    UIkit.notification({
                        message: this.$t('unexpected.error'),
                        status: 'error',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });
                });
        },
        actionDeleteFile() {
            let http = axios.create();

            let path = '';
            if (this.item.path !== '/' && this.item.path !== '') {
                path = '/folders/' + this.item.path;
            }

            // this.deleteLoading = true;
            http.delete(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.galleryId +
                    path +
                    '/files/' +
                    this.item.name
            )
                .then(() => {
                    this.$emit('deleted', this.itemKey);
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    UIkit.notification({
                        message: this.$t('unexpected.error'),
                        status: 'error',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });
                });
        },
        actionDeleteFolder() {
            let http = axios.create();

            this.createLoading = true;
            http.delete(
                this.apiUrl +
                    '/manage-galleries/' +
                    this.galleryId +
                    '/folders/' +
                    this.item.name
            )
                .then(() => {
                    this.$emit('deleted', this.itemKey);
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    UIkit.notification({
                        message: this.$t('unexpected.error'),
                        status: 'error',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.image-item-struct {
    max-height: 150px;
    margin: auto;
}

.image-item {
    height: 100%;
    margin: auto;
}

.folder-struct {
    color: #000000 !important;
}

.name {
    color: #000 !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 4px;
}
</style>
