<template>
    <div>
        <Transfer v-if="token" :token="token" />
    </div>
</template>

<script>
import axios from 'axios';

const navigatorLanguages = [
    {
        key: 'de',
        codes: ['de', 'de-*'],
    },
    {
        key: 'ch',
        codes: ['ch', 'ch-*'],
    },
    {
        key: 'at',
        codes: ['at', 'at-*'],
    },
    {
        key: 'li',
        codes: ['li', 'li-*'],
    },
    {
        key: 'en',
        codes: ['en', 'en-*'],
    },
    {
        key: 'it',
        codes: ['it', 'it-*'],
    },
    {
        key: 'fr',
        codes: ['fr', 'fr-*'],
    },
    {
        key: 'nl',
        codes: ['nl', 'nl-*'],
    },
    {
        key: 'fi',
        codes: ['fi', 'fi-*'],
    },
];

const allowedLanguages = ['de', 'ch', 'at', 'li', 'en', 'it', 'fr', 'nl', 'fi'];

import Vue from 'vue';
import Transfer from '@/views/Transfer.vue';

export default {
    name: 'Lang',
    components: {Transfer},
    data() {
        return {
            token: null,
        };
    },
    created() {
        this.getNode();
    },
    methods: {
        getNode() {
            let http = axios.create();
            http.get(this.apiUrl + '/mode')
                .then((response) => {
                    this.$store.state.mode = response.data.mode;
                    this.error = null;
                    this.loading = false;

                    if (response.data.mode !== 'swifter') {
                        this.process();
                    } else {
                        const parts = this.$route.path.split('/');
                        this.token = parts[2];
                    }
                })
                .catch((e) => {
                    this.checkGenericError(e);
                    this.error = e;
                    this.loading = false;
                });
        },
        process() {
            let lang = Vue.localStorage.get('language');

            if (!allowedLanguages.includes(lang)) {
                for (let i = 0, j = navigatorLanguages.length; i < j; i++) {
                    let navigatorLanguage = navigator.language;
                    navigatorLanguage = navigatorLanguage.toLowerCase();

                    let wildcard = navigatorLanguage.substring(0, 3) + '*';

                    if (
                        navigatorLanguages[i].codes.includes(
                            navigatorLanguage
                        ) ||
                        navigatorLanguages[i].codes.includes(wildcard)
                    ) {
                        lang = navigatorLanguages[i].key;
                        break;
                    }
                }

                if (!lang) {
                    lang = 'de';
                }
            }

            this.$i18n.locale = lang;
            Vue.localStorage.set('language', lang);

            this.$router.push('/' + lang);
        },
    },
};
</script>

<style lang="scss" scoped></style>
