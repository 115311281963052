<template>
    <div>
        <div class="blank-struct" />

        <LoadingStruct
            class="transfer-struct"
            is-dark
            :is-loading="loading"
            :error="error">
            <countdown
                v-if="
                    transfer && transfer.transfer && transfer.transfer.expiresAt
                "
                class="uk-hidden"
                :end-time="new Date(transfer.transfer.expiresAt).getTime()"
                @finish="reload" />

            <Card
                is-round
                v-if="error && error.message.includes('404')"
                :title="$t('transfer.does.not.exist')"
                is-dark
                class="uk-text-left"
                mode="default"
                is-centered>
                <InfoBox class="uk-text-left" mode="danger">
                    <Translate
                        translation-key="transfer.does.not.exist.message" />
                </InfoBox>
            </Card>

            <Card
                v-else
                is-round
                :title="transfer.transfer.name"
                is-dark
                mode="default"
                is-centered>
                <a
                    :uk-tooltip="
                        'title:' +
                        $t(
                            disabledDownload
                                ? 'transfer.available.shortly'
                                : 'download'
                        )
                    "
                    :href="
                        disabledDownload
                            ? '#'
                            : apiUrl +
                              '/transfers/' +
                              transferToken +
                              '/' +
                              transfer.transfer.name +
                              '?session=' +
                              transfer.token
                    ">
                    <Button
                        :is-disabled="disabledDownload"
                        is-round
                        is-large
                        is-min-width
                        mode="success"
                        :is-loading="loadingDownload">
                        <countdown
                            v-if="disabledDownload"
                            :end-time="
                                new Date(transfer.accessibleAt).getTime()
                            "
                            @finish="unlockDownload">
                            <span slot="process" slot-scope="{timeObj}">
                                {{ timeObj.ceil.s }}
                            </span>
                        </countdown>

                        <Icon v-else icon="cloud-download" :ratio="1.4" />
                    </Button>
                </a>

                <div class="uk-margin-small-top">
                    {{ getParsedBytes(transfer.transfer.sizeInBytes) }}
                </div>

                <div
                    class="uk-margin-medium-top uk-text-left word-struct"
                    v-if="transfer.transfer.message">
                    <InfoBox
                        class="uk-text-center"
                        borderless
                        slim
                        is-round
                        mode="blank">
                        {{ transfer.transfer.message }}
                    </InfoBox>
                </div>

                <div class="uk-margin-medium-top word-struct">
                    <InfoBox borderless mode="blank" slim is-round>
                        <span
                            :uk-tooltip="
                                'title:' +
                                $t('On') +
                                ' ' +
                                getFormattedDateTimeByDateString(
                                    transfer.transfer.expiresAt
                                ) +
                                ' ' +
                                $t('clock')
                            ">
                            <span class="expires-at-text">
                                <Translate translation-key="expires.at" />
                                {{
                                    getDaysLeftByDateString(
                                        transfer.transfer.expiresAt,
                                        false
                                    )
                                }}
                            </span>
                        </span>
                    </InfoBox>
                </div>

                <div class="uk-margin-top">
                    <div class="transfer-report">
                        <a
                            class="uk-link-muted"
                            target="_blank"
                            :href="getReportURL()">
                            <Translate translation-key="transfer.report" />
                        </a>
                    </div>
                    <Translate
                        class="transfer-report-message"
                        translation-key="transfer.report.message" />
                </div>
            </Card>
        </LoadingStruct>
    </div>
</template>

<script>
import LoadingStruct from '@/views/LoadingStruct';
import Card from '@/components/generic/Card';
import axios from 'axios';
import Translate from '../components/Translate.vue';
import Button from '../components/generic/Button.vue';
import InfoBox from '../components/generic/InfoBox.vue';
import Icon from '@/components/generic/Icon.vue';

export default {
    name: 'Transfer',
    components: {
        Icon,
        InfoBox,
        Button,
        Translate,
        Card,
        LoadingStruct,
    },
    props: {
        token: String,
    },
    data() {
        return {
            loading: true,
            error: null,

            transfer: {
                accessibleAt: '',
                token: '',
                transfer: {
                    expiresAt: '',
                    name: '',
                    sizeInBytes: 0,
                },
            },

            loadingDownload: false,
            disabledDownload: true,

            transferToken: '',

            heartbeatInterval: 0,
        };
    },
    created() {
        if (this.token) {
            this.transferToken = this.token;
        } else {
            this.transferToken = this.$route.params.transfer;
        }

        this.getTransfer();
    },
    beforeDestroy() {
        clearInterval(this.heartbeatInterval);
    },
    methods: {
        unlockDownload() {
            this.disabledDownload = false;
        },
        getReportURL() {
            return (
                'https://phy-prints.com/report?report=' + window.location.href
            );
        },
        heartbeatTransfer() {
            const transferToken = this.transferToken;

            let http = axios.create();
            http.patch(
                this.apiUrl +
                    '/transfers/' +
                    transferToken +
                    '/sessions/' +
                    this.transfer.token
            )
                .then(() => {})
                .catch(() => {});
        },
        getTransfer() {
            let self = this;
            const transferToken = this.transferToken;

            let http = axios.create();
            http.get(this.apiUrl + '/transfers/' + transferToken)
                .then((response) => {
                    this.transfer = response.data;

                    this.error = null;
                    this.loading = false;

                    setInterval(function () {
                        self.heartbeatTransfer();
                    }, 500);
                })
                .catch((e) => {
                    this.checkGenericError(e);
                    this.error = e;
                    this.loading = false;
                });
        },
        actionDownload() {
            event.preventDefault();
            const transferToken = this.$route.params.transfer;

            let self = this;
            self.loadingDownload = true;

            let request = new XMLHttpRequest();
            request.open(
                'GET',
                this.apiUrl +
                    '/transfers/' +
                    transferToken +
                    '/' +
                    self.transfer.transfer.name,
                true
            );
            request.responseType = 'blob';
            request.onload = function () {
                let blob = request.response;
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = self.transfer.transfer.name;
                link.click();

                self.loadingDownload = false;
            };

            request.send();
        },
    },
};
</script>

<style lang="scss" scoped>
.word-struct {
    word-wrap: break-word;
}

.transfer-struct {
    max-width: 500px;
    min-height: calc(100vh - 217px);
}

.expires-at-text {
    color: #999;
}

.transfer-report {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
}

.transfer-report-message {
    color: #999;
    font-size: 13px;
}
</style>
