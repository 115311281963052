<template>
    <span :uk-tooltip="disabled ? 'title:'+$t('x.module.disabled.hint', {x: $t('whiteLabel')}) : ''"
          :class="disabled ? 'module-disabled-struct' : ''">

        <span :class="disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'">
            <Card is-dark mode="default" id="favicon-form" class="uk-height-1-1">
                <LabelWithError is-label-large :error="inputError" :label="$t(this.title)" is-dark/>

                <Modal id="modal-favicon" is-without-body is-close-outside>
                    <div class="uk-text-center favicon-struct">
                        <img v-if="input" class="favicon" :src="getLocalFileUrl(input)" uk-img/>
                        <img v-else-if="favicon" class="favicon"
                             :src="apiUrl+'/manage-galleries/'+id+  (folder ? '/folders/'+folder.name : '')+  '/thumbnail/'+favicon+'?token='+getToken"
                             uk-img/>
                        <img v-else class="favicon" :src="require('../../assets/default_thumbnail.jpg')" uk-img/>
                     </div>
                 </Modal>


                <div class="uk-position-relative favicon-struct uk-text-center" id="favicon-upload">
                    <img v-if="input" class="favicon" :src="getLocalFileUrl(input)" uk-img/>
                    <img v-else-if="favicon" class="favicon"
                         :src="apiUrl+'/manage-galleries/'+id+  (folder ? '/folders/'+folder.name : '')+ '/thumbnail/'+favicon+'?token='+getToken"
                         uk-img/>
                    <img v-else class="favicon" :src="require('../../assets/default_thumbnail.jpg')" uk-img/>
                </div>

                <div class="uk-card uk-card-body uk-padding-xsmall control secondary-with-border">
                    <div class="uk-flex-center uk-flex" uk-grid>

                        <div>
                            <span v-on:click="triggerInputDialog()">
                                <Icon :ratio="1.3"
                                      :uk-tooltip="'title:'+$t('replace')"
                                      class="cursor-pointer action-icon"
                                      icon="cloud-upload"/>
                            </span>

                            <input id="thumbnail"
                                   ref="thumbnail"
                                   :accept="'.'+fileExtensions.join(', .')"
                                   type="file"
                                   class="uk-hidden"
                                   v-on:click="$refs.thumbnail.value=null"
                                   v-on:change="handleChange()"
                            />
                        </div>

                        <div v-if="false">
                            <ToggleItem toggle="target: #modal-favicon">
                                <Icon :ratio="1.3" :uk-tooltip="'title:'+$t('show')"
                                      class="cursor-pointer action-icon"
                                      icon="search"/>
                            </ToggleItem>
                        </div>

                        <div v-if="input || favicon">
                            <span v-on:click="handleDelete()">
                                <Icon :ratio="1.5"
                                      :uk-tooltip="'title:'+$t('delete')"
                                      class="cursor-pointer action-icon"
                                      icon="trash"/>
                            </span>
                        </div>

                    </div>
                </div>

                <div class="uk-margin-medium-bottom"/>
                <Button :action="actionUpdate"
                        :is-loading="loading"
                        :text="$t('x.save', {x: $t(title)})"
                        has-action
                        is-large
                        is-max
                        mode="primary"
                />

                <div v-if="error" class="uk-margin-medium-bottom"/>
                <ErrorMessage :error="error"/>

                <div v-if="success" class="uk-margin-medium-bottom"/>
                <SuccessMessage :success="success" :message="$t('x.changes.saved', {x: $t(title)})"/>

            </Card>
        </span>

    </span>
</template>


<script>

import SuccessMessage from "@/components/generic/SuccessMessage.vue";
import Button from "@/components/generic/Button.vue";
import Card from "@/components/generic/Card.vue";
import ErrorMessage from "@/components/generic/ErrorMessage.vue";
import axios from "axios";
import Icon from "@/components/generic/Icon.vue";
import Modal from "@/components/generic/Modal.vue";
import ToggleItem from "@/components/generic/ToggleItem.vue";
import LabelWithError from "@/components/generic/LabelWithError.vue";

export default {
    name: 'GalleryThumbnailForm',
    components: {LabelWithError, ToggleItem, Modal, Icon, ErrorMessage, Card, Button, SuccessMessage},
    props: {
        data: String,
        folder: Object,

        allowedExtensions: Array,
        allowedSize: Number,

        disabled: Boolean,
        id: String,
    },
    created() {
        if (this.folder) {
            this.favicon = this.folder.thumbnail;

        } else {
            this.favicon = this.data;

        }
    },
    mounted() {
        // window.addEventListener('dragover', this.dragoverListener, false);
        // window.addEventListener('drop', this.dropListener, false);
    },
    data() {
        return {
            title: "thumbnail",

            loading: false,
            success: false,
            error: null,

            input: null,
            inputError: null,
            favicon: null,
        }
    },
    computed: {
        fileExtensions() {
            const extensions = this.allowedExtensions;

            const lower = extensions.map(element => {
                return element.toLowerCase();
            });

            return [...new Set(lower)];
        },
    },
    watch: {
        folder() {
            if (this.folder) {
                this.favicon = this.folder.thumbnail;

            } else {
                this.favicon = this.data;

            }
        },
    },
    methods: {
        dragoverListener(e) {
            e.preventDefault();

            let element = document.getElementById('thumbnail-upload');
            if (element) {
                element.classList.add('dragover');
            }
        },
        dropListener(e) {
            e.preventDefault();

            let element = document.getElementById('thumbnail-upload');
            if (element) {
                element.classList.remove('dragover');
            }

            let files = e.dataTransfer.files;

            this.handleFileInput(files[0]);
        },
        triggerInputDialog() {
            document.getElementById(this.title).click();
        },
        handleDelete() {
            this.success = false;
            this.favicon = null;
            this.input = null;
        },
        handleChange() {
            let file = this.$refs[this.title].files[0];
            if (!file) {
                return;
            }

            this.handleFileInput(file);
        },
        handleFileInput(file) {
            let fileExtension = file.name.split('.').pop();
            fileExtension = fileExtension.toLowerCase();
            if (!this.fileExtensions.includes(fileExtension)) {
                this.inputError = this.$t("file.type.error", {
                    name: this.$t(this.title),
                    types: this.getFormattedTypesString(this.fileExtensions)
                });

                return;
            }

            if (file.size > (this.allowedSize)) {
                this.inputError = this.$t("name.can.be.maximum.size", {
                    name: this.$t(this.title),
                    size: this.getParsedBytes(this.allowedSize),
                });
                return;
            }

            this.inputError = null;
            this.error = null;
            this.success = false;
            this.input = file;
        },
        actionUpdate() {
            let formData = new FormData();
            formData.append("file", this.input);

            const config = {
                headers: {
                    'deleteFile': this.favicon === null ? 'true' : 'false',
                },
            }

            this.loading = true;

            let folder = "";
            if (this.folder) {
                folder = "/folders/" + this.folder.name;
            }

            let http = axios.create();
            http.patch(this.apiUrl + '/manage-galleries/' + this.id + folder + '/thumbnail', formData, config)
                .then((response) => {
                    this.error = null;
                    this.success = true;
                    this.loading = false;

                    this.$emit('updated', response.data);

                })
                .catch(e => {
                    this.checkGenericError(e);

                    this.error = e;
                    this.success = false;
                    this.loading = false;

                });
        },
    }
}

</script>


<style lang="scss" scoped>

.favicon {
    max-width: 256px;
    max-height: 256px;

    //width: 100%;
    //height: 100%;
}

.favicon-struct {
    padding: 15px;
    background-color: rgba(34, 34, 34, 0.72);
    border: 1px rgba(34, 34, 34, 0.72) solid;
    backdrop-filter: saturate(180%) blur(20px);
    transition: background-color .25s linear;
}

.dragover {
    background-color: rgba(34, 34, 34, 0.4);
    border: 1px rgba(34, 34, 34, 0.72) dashed;
}

</style>
